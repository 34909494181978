<a  (click)="openModal(template)" style="cursor: pointer;">
  <i class="fa fa-info-circle" aria-hidden="true" style="padding-top:0.6rem; font-size: 2.3rem;color: white;"></i></a>
<template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'HelpBox' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <i class="fa fa-users"></i> {{'MeetingAtPlace' | translate}}<br />
      <i class="fa fa-skype"></i> {{'MeetingWithSkype' | translate}}<br />
      <i class="fa fa-phone"></i> {{'MeetingOnPhone' | translate}}<br />
      <i class="fa fa-video-camera"></i> {{'MeetingOnVideoconference' | translate}}<br />
      <i class="fa fa-question-circle" style="color:red"></i> {{'WaitForApproval' | translate}}<br />
      <i class="fa fa-calendar-check-o" style="color:green"></i> {{'AppointmentApproved' | translate}}<br />
      <i class="fa fa-exclamation-circle" aria-hidden="true" style="color:red"></i> {{'WaitingForRating' | translate}}<br />
      <i class="fa fa-check-square" aria-hidden="true" style="color:green"></i> {{'TaskFinished' | translate}}<br />
      <br />
      <a href="Help/ApptFinder-Anleitung_Outlook.pdf" target="_blank">Anleitung für Outlook Kalenderfreigabe</a><br/>
      <a href="Help/ApptFinder-Anleitung_Stv.pdf" target="_blank">Anleitung für Stv. Bestimmung</a><br/>
      <a href="Help/ApptFinder-Anleitung_Termine.pdf" target="_blank">Anleitung für Termine</a><br/>
    </p>
  </div>
</template>
