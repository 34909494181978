<table class="table table-bordered table-striped table-responsive">
  <tr>
    <td></td>
    <td>{{'Yes' | translate}}</td>
    <td>{{'No' | translate}}</td>
  </tr>
  <tr>
    <td>{{'handicapText' | translate}}
    </td>
    <td><input type="radio" [(ngModel)]="suitabilityHandicapViewModel.handicap" name="handicap" (change)="valuesChanged()" [value]="true" [disabled]="readonly" /></td>
    <td><input type="radio" [(ngModel)]="suitabilityHandicapViewModel.handicap" name="handicap" (change)="valuesChanged()" [value]="false" [disabled]="readonly" /></td>
  </tr>
</table>
