
<table class="table table-bordered table-striped table-responsive">
  <tr>
    <td style="width: 400px;"></td>
    <td style="text-align: center">{{'German' | translate}}</td>
    <td style="text-align: center">{{'Englisch' | translate}}</td>
    <td style="text-align: center">{{'Other' | translate}}</td>
    <td>{{'Anmerkungen' | translate}}</td>
  </tr>
  <tr>
    <td>{{'NativeLanguageProficiency' | translate}}</td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.nativeLanguageProficiency.german" (change)="valuesChanged()" /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.nativeLanguageProficiency.english" (change)="valuesChanged()" /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.nativeLanguageProficiency.other" (change)="valuesChanged()" /></td>
    <td><input type="text" [(ngModel)]="suitabilityLanguagesViewModel.nativeLanguageProficiency.notes" class="form-control" (keyup)="valuesChanged()" (change)="valuesChanged()" /></td>
  </tr>
  <tr>
    <td>{{'C1CertificateLanguageProficiency' | translate}}</td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.c1CertificateLanguageProficiency.german" (change)="valuesChanged()" /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.c1CertificateLanguageProficiency.english" (change)="valuesChanged()" /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.c1CertificateLanguageProficiency.other" (change)="valuesChanged()" /></td>
    <td><input type="text" [(ngModel)]="suitabilityLanguagesViewModel.c1CertificateLanguageProficiency.notes" class="form-control" (keyup)="valuesChanged()" (change)="valuesChanged()" /></td>
  </tr>
  <tr>
    <td>{{'C1OtherLanguageProficiency' | translate}}</td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.c1OtherLanguageProficiency.german" (change)="valuesChanged()"  /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.c1OtherLanguageProficiency.english" (change)="valuesChanged()"  /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.c1OtherLanguageProficiency.other" (change)="valuesChanged()"  /></td>
    <td><input type="text" [(ngModel)]="suitabilityLanguagesViewModel.c1OtherLanguageProficiency.notes" class="form-control" (keyup)="valuesChanged()" (change)="valuesChanged()" /></td>
  </tr>
  <tr>
    <td>{{'B2CertificateLanguageProficiency' | translate}}</td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.b2CertificateLanguageProficiency.german" (change)="valuesChanged()" /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.b2CertificateLanguageProficiency.english" (change)="valuesChanged()" /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.b2CertificateLanguageProficiency.other" (change)="valuesChanged()" /></td>
    <td><input type="text" [(ngModel)]="suitabilityLanguagesViewModel.b2CertificateLanguageProficiency.notes" class="form-control" (keyup)="valuesChanged()" (change)="valuesChanged()" /></td>
  </tr>
  <tr>
    <td>{{'B2OtherLanguageProficiency' | translate}}</td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.b2OtherLanguageProficiency.german" (change)="valuesChanged()" /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.b2OtherLanguageProficiency.english" (change)="valuesChanged()" /></td>
    <td style="text-align: center"><input type="checkbox" [(ngModel)]="suitabilityLanguagesViewModel.b2OtherLanguageProficiency.other" (change)="valuesChanged()" /></td>
    <td><input type="text" [(ngModel)]="suitabilityLanguagesViewModel.b2OtherLanguageProficiency.notes" class="form-control" (keyup)="valuesChanged()" (change)="valuesChanged()" /></td>
  </tr>
  <tr>
    <td>{{'LanguageGeneralNotes' | translate}}</td>
    <td colspan="4"><textarea type="text" class="form-control" style="min-height: 100px;" [(ngModel)]="suitabilityLanguagesViewModel.languageGeneralNotes" (keyup)="valuesChanged()" (change)="valuesChanged()" ></textarea></td>
  </tr>
</table>
