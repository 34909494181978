<div id="sidebar-wrapper" style="padding: .55rem;">
  <div class="panel panel-default">
    <div class="panel-heading">
      <span>{{'Configurations' | translate}}</span>
      <span class="pull-right">
        <a class="btn btn-default btn-xs" style="margin-right:1rem;" routerLink="/configs/new"><i class="fa fa-plus"></i></a>
        <span class="badge">{{bookingConfigs?.length}}</span>
      </span>
    </div>
    <div class="list-group">
      <a *ngFor="let bookingConfig of bookingConfigs | orderBy : ['-start']"
         [routerLink]="'/configs/'+bookingConfig.id"
         [routerLinkActive]="'active'"
         class="list-group-item">
        <div class="row">
          <div>
            <div style="margin-left: 28px; font-weight: bold" [ngClass]="{'inactive': bookingConfig.passed }">{{bookingConfig.name}} / {{bookingConfig.start | datex: 'DD.MM.YYYY'}} - {{bookingConfig.end | datex: 'DD.MM.YYYY'}}</div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
