<div class="row" style="background-color:white;margin: 1rem;padding: 1rem;" appAutoWindowHeight [marginTop]="88">
  <div class="col-md-12">
    <h3>
      {{'InterestedStudentsOverview' | translate}}
      <button class="btn btn-default pull-right" (click)="refresh()">
        <i class="fa fa-refresh"></i>
      </button>    
      <button class="btn btn-default pull-right" (click)="export()">
        <i class="fa fa-file-excel-o"></i>
      </button>    
    </h3>
    <app-ngx-filter-table [data]="data"
                          [options]="options"
                          [styleClass]="'table table-bordered table-condensed table-responsive table-striped table-hover filtertable-table'"
                          [maxRowsPerPage]="10">

    </app-ngx-filter-table>
    
  </div>

  <div class="col-md-12">
    <h3>
      {{'InterestedStudentsOverviewPrevious' | translate}}
      <!-- <button class="btn btn-default pull-right" (click)="refresh()">
        <i class="fa fa-refresh"></i>
      </button>     -->
    </h3>
    <app-ngx-filter-table *ngIf="hasPrevious"
      [data]="dataPrevious"
      [options]="optionsPrevious"
      [styleClass]="'table table-bordered table-condensed table-responsive table-striped table-hover filtertable-table'"
      [maxRowsPerPage]="10">
    </app-ngx-filter-table>
    <label *ngIf="hasPrevious === false">
      {{'InterestedStudentsOverviewPreviousNoData' | translate}}
    </label>
    <div *ngIf="hasPrevious === undefined">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>