<div class="row" *ngIf="employee">
  <div class="col-md-8 col-md-offset-2" style="background-color: white;padding: 36px 36px 10px;">
    <p>
      <b>{{'HelloStudent' | translate}} {{('StudentAddress.' + studentAppointmentsData.student.geschlecht) | translate }}{{studentAppointmentsData.student.firstName}} {{studentAppointmentsData.student.lastName}}</b><br/><br/>
      <span [innerHTML]="'BookingConfirmationText' | translate"></span>
    </p>
    <p>{{'ConversationTopicsAre' | translate}}</p>
    <ul>
      <li *ngFor="let topic of studentAppointmentsData.subject.topics">{{topic}}</li>
    </ul>

    <p>{{'LookingForwardText' | translate}}
    <p>
    <span>{{'KindRegards' | translate}}</span><br/>
      <span *ngIf="employee" style="font-size: 16px;">
        <span>{{TitleAndName}}</span><br/>
        <span *ngIf="employee.signature.function">{{employee.signature.function}}<br /></span><br />
        <span><b>{{'SchoolSignature' | translate}}</b><br /></span>
        <span *ngIf="employee.signature.division">{{employee.signature.division}}<br /></span>
        <span>Zürcher Hochschule für Angewandte Wissenschaften</span>
        <span *ngIf="employee.signature.address"><br/><span [innerHTML]="employee.signature.address | includeLineBreaks"></span></span>
    <span><br />Postfach</span><br/>
    <span>8401 Winterthur</span><br/><br/>
      </span>


        <span *ngIf="employee && employee.signature.telNrShort">Tel. +41 58 934 {{employee.signature.telNrShort}}<br/></span>
      {{employee.pid}}@zhaw.ch<br />
      <span *ngIf="employee.signature.skypeName">{{'SkypeName' | translate }}: {{employee.signature.skypeName}}<br/></span>
    <a target="_blank" href="http://www.zhaw.ch/sml">www.zhaw.ch/sml</a><br />
    <a target="_blank" href="http://www.zhaw.ch/de/sml/studium/master">www.zhaw.ch/de/sml/studium/master</a>
    </p>
    <br />
    <table class="table table-responsive table-bordered" style="border-left:0;border-right:0;">
      <tr>
        <td><label>{{'StudyBranch' | translate}}</label></td>
        <td>{{studentAppointmentsData.subject.studyBranchName}}</td>
      </tr>
      <tr>
        <td><label>{{'Date' | translate}}</label></td>
        <td>{{confirmedAppointment.start | datex: "DD.MM.YYYY"}}</td>
      </tr>
      <tr>
        <td><label>{{'Time' | translate}}</label></td>
        <td>
          {{confirmedAppointment.start | datex: "HH:mm"}} - {{confirmedAppointment.end | datex: "HH:mm"}}
          <span *ngIf="isDifferent(confirmedAppointment.start)">
            <br/> {{'YourTime' | translate }}: {{confirmedAppointment.start | datex: 'DD.MM.YYYY HH:mm': true}} - {{confirmedAppointment.end | datex: 'HH:mm': true}}
          </span>
        </td>
      </tr>
      <tr>
        <td><label>{{'Meetingstype' | translate}}</label></td>
        <td>{{getMeetingType(confirmedAppointment.meetingTypeId).label}}</td>
      </tr>
      <tr *ngIf="confirmedAppointment.meetingInfo">
        <td><label>{{'SkypeName' | translate}}</label></td>
        <td>{{confirmedAppointment.meetingInfo}}</td>
      </tr>
      <tr>
        <td><label>{{'PhoneNr' | translate}}</label></td>
        <td>{{confirmedAppointment.studentTelefonNr || '-'}}</td>
      </tr>
      <tr *ngIf="appointmentMeetingoptionsTelefon != confirmedAppointment.meetingTypeId && appointmentMeetingoptionsSkype != confirmedAppointment.meetingTypeId && (confirmedAppointment.place || confirmedAppointment.placeAdditional)">
        <td><label>{{'Place' | translate}}</label></td>
        <td>
          <span style="white-space: pre-line;" *ngIf="confirmedAppointment.place">{{confirmedAppointment.place || '-'}}</span>
          <span *ngIf="confirmedAppointment.placeAdditional && confirmedAppointment.place"><br /></span>
          <span *ngIf="confirmedAppointment.placeAdditional">{{confirmedAppointment.placeAdditional || '-'}}</span>
        </td>
      </tr>
      <tr *ngIf="appointmentMeetingoptionsVorOrt == confirmedAppointment.meetingTypeId">
        <td><label>{{'Locations' | translate}}</label></td>
        <td>
          <a target="_blank" href="https://www.zhaw.ch/de/sml/ueber-uns/standorte-und-infrastruktur/">https://www.zhaw.ch/de/sml/ueber-uns/standorte-und-infrastruktur</a><br />
          <a target="_blank" href="https://www.kvz-weiterbildung.ch/sihlpost/sihlpost-bildung-im-zentrum/">https://www.kvz-weiterbildung.ch/sihlpost/sihlpost-bildung-im-zentrum</a>
        </td>
      </tr>
    </table>
  </div>
</div>
