<div fxLayout="row" fxFill style="background-color:#CDDEFF;">
  <div fxFlex="17" appAutoWindowHeight [marginTop]="55" style="background-color:white;">
    <app-booking-config-list [bookingConfigs]="bookingConfigs"></app-booking-config-list>

  </div>
  <div fxFlex appAutoWindowHeight [marginTop]="56" style="padding:0rem 3rem;background-color: white;">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
