<button type="button" class="btn btn-default btn-xs" (click)="openModal(template)">
  <i class="fa fa-pencil" aria-hidden="true"></i></button>
<template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'QualificationsDetails' | translate}} {{'Editing' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-responsive table-striped table-bordered" >
          <thead>
          <tr>
            <td>{{'Module' | translate}}</td>
            <td>{{'ModuleAbbreviation' | translate}}</td>
            <td>{{'ECTS' | translate}}</td>
            <td>{{'PossibleImplementation' | translate}}</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let qualification of suitabilityQualificationsViewModel.qualifications">
            <td>{{qualification.moduleName}}</td>
            <td>{{qualification.moduleNameShort}}</td>
            <td>{{qualification.ects}}</td>
            <td>
              <select class="form-control" [(ngModel)]="qualification.possibleImplementation"
                      [ngStyle]="(!qualification.possibleImplementation || qualification.possibleImplementation == '00000000-0000-0000-0000-000000000000') && {'border': '1px solid #c60000'}">
                <option name="possibleImplementation" [value]="hs">HS</option>
                <option name="possibleImplementation" [value]="fs">FS</option>
                <option name="possibleImplementation" [value]="both">HS/FS</option>
              </select>
            </td>
            <td>
              <button class="btn btn-danger btn-xs" (click)="removeQualification(qualification)"><i class="fa fa-times"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" *ngIf="bsc.selectables.length > 0">
      <div class="col-md-10">
        <ng-select
          #mSelected
          [active]="bsc.active"
          [allowClear]="'true'"
          [items]="bsc.selectables"
          [placeholder]="'SearchForBScModulePlaceholder' | translate"
          (selected)="bsc.select($event)"
          (removed)="bsc.reset()"
        ></ng-select>
      </div>
      <div class="col-md-2">
        <button class="btn btn-primary" (click)="addFromControl(bsc)" [disabled]="!bsc.hasSelected"><i class="fa fa-plus"></i></button>
      </div>
    </div>
    <br/>
    <div class="row" *ngIf="msc.selectables.length > 0">
      <div class="col-md-10">
        <ng-select
          #bSelected
          [active]="msc.active"
          [allowClear]="'true'"
          [items]="msc.selectables"
          [placeholder]="'SearchForModulePlaceholder' | translate"
          (selected)="msc.select($event)"
          (removed)="msc.reset()"
        ></ng-select>
      </div>
      <div class="col-md-2">
        <button class="btn btn-primary" (click)="addFromControl(msc)" [disabled]="!msc.hasSelected"><i class="fa fa-plus"></i></button>
      </div>
    </div>
    <br/>
    <br/>

    <div class="row">
      <div class="col-md-12">
          <button class="btn btn-primary pull-right" (click)="saveModules()" [disabled]="!isValid()">{{'Save' | translate}}</button>
      </div>
    </div>
  </div>
</template>

