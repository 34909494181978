<div *ngIf="editBookingConfig">
  <!-- header -->
  <div class="page-header">
    <h2>
      <span *ngIf="editBookingConfig.name">
        {{editBookingConfig.name}}
        <span *ngIf="editBookingConfig.start"> / {{editBookingConfig.start | datex: 'DD.MM.YYYY'}}</span>
        <span *ngIf="editBookingConfig.end"> - {{editBookingConfig.end | datex: 'DD.MM.YYYY'}}</span>
      </span>
      <span *ngIf="!editBookingConfig.name"><i>{{'New' | translate}}</i></span>
    </h2>
  </div>

  <!-- action bar -->
  <div class="well well-sm" [ngStyle]="!this.isPristine && {'background-color': '#fcf8e3'}">
    <button type="button" [disabled]="isPristine || saving || !canSave" class="btn btn-success" (click)="saveBookingConfig()">
      <i class="fa fa-save"></i> {{'Save' | translate}}
    </button>
    <button *ngIf="!editBookingConfig.isNew" type="button" [disabled]="isPristine || saving" class="btn btn-danger" style="margin-left: 20px;" (click)="removeChanges()">
      <i class="fa fa-trash" aria-hidden="true" ></i> {{'DiscardChanges' | translate}}
    </button>
  </div>
  <tabset>
    <tab heading="Stammdaten">
      <div class="row">
        <div class="col-md-6">
          <div style="padding-top: 2rem;">
            <table class="table table-responsive table-bordered">
              <tr>
                <td>{{'Name' | translate}}</td>
                <td><input type="text" class="form-control" [(ngModel)]="name"
                           [disabled]="!canEditName()" [ngClass]="{'hasError': conflictName}"/></td>
              </tr>
              <tr>
                <td>{{'StartDate' | translate}}</td>
                <td>
                  <app-datepicker [(ngModel)]="start" [placeholder]="'ChooseDate' | translate"
                                  [disabled]="!canEditStartDate()" [ngClass]="{'hasError': conflictDate}"></app-datepicker>
                </td>
              </tr>
              <tr>
                <td>{{'EndDate' | translate}}</td>
                <td>
                  <app-datepicker [(ngModel)]="end" [placeholder]="'ChooseDate' | translate"
                                  [disabled]="!canEditEndDate()" [ngClass]="{'hasError': conflictDate}"></app-datepicker>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <button *ngIf="!editBookingConfig.passed && canStartLocalImport" class="btn btn-md btn-primary" (click)="import()" [disabled]="importing">Studenten importieren (lokal)</button>

    </tab>
  </tabset>
</div>

<div *ngIf="!editBookingConfig">
  <app-loading-indicator></app-loading-indicator>
</div>
