<div class="row">
  <div class="col-md-8 col-md-offset-2" style="background-color: white;padding: 36px 36px 10px;">
    <p>
      <b>{{'WelcomeStudent' | translate}} {{('StudentAddress.' + studentAppointmentsData.student.geschlecht) | translate }}{{studentAppointmentsData.student.firstName}} {{studentAppointmentsData.student.lastName}}</b><br/><br/>
      <span [innerHTML]="'AppointmentAlreadyBookedText' | translate"></span>
      <br/>
      <br/>
    </p>
    <br />
    <br />
    <!-- Booking details -->
    <table class="table table-responsive table-bordered" style="border-left:0;border-right:0;">
      <tr>
        <td><label>{{'StudyBranch' | translate}}</label></td>
        <td>{{studentAppointmentsData.subject.studyBranchName}}</td>
      </tr>
      <tr>
        <td><label>{{'Date' | translate}}</label></td>
        <td>{{bookedAppointment.start | datex: 'DD.MM.YYYY'}}</td>
      </tr>
      <tr>
        <td><label>{{'Time' | translate}}</label></td>
        <td>{{bookedAppointment.start | datex: 'HH:mm'}} - {{bookedAppointment.end | datex: 'HH:mm'}}</td>
      </tr>
      <tr>
        <td><label>{{'Meetingstype' | translate}}</label></td>
        <td>{{ meetingOptionService.getMeetingOptionById(bookedAppointment.meetingTypeId).label }}</td>
      </tr>
      <tr *ngIf="bookedAppointment.meetingInfo">
        <td><label>{{'SkypeName' | translate}}</label></td>
        <td style="white-space: pre-line;">{{bookedAppointment.meetingInfo}}</td>
      </tr>
      <tr>
        <td><label>{{'PhoneNr' | translate}}</label></td>
        <td>{{bookedAppointment.studentTelefonNr || '-'}}</td>
      </tr>
    </table>
  </div>
</div>
