<table class="table table-bordered table-striped table-responsive">
  <tr>
    <td></td>
    <td>{{'Fulfilled' | translate}}</td>
    <td>{{'NotFulfilled' | translate}}</td>
  </tr>
  <tr *ngFor="let admission of admissionsCriteriaViewModel.admissionsCriteria; let i = index ">
    <td>{{admission.criteria}}</td>
    <td><input type="radio" [(ngModel)]="admission.fulfilled" [name]="i" (change)="valuesChanged()" [value]="true" [disabled]="readonly" /></td>
    <td><input type="radio" [(ngModel)]="admission.fulfilled" [name]="i" (change)="valuesChanged()" [value]="false" [disabled]="readonly" /></td>
  </tr>
  <tr style="height:2rem;">
    <td>&nbsp;</td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td>{{'generalAdmissionCriteriaAcceptanceText' | translate}}
    </td>
    <td><input type="radio" [(ngModel)]="admissionsCriteriaViewModel.generalAdmissionCriteriaAcceptance" name="generalAdmissionCriteriaAcceptance" (change)="valuesChanged()" [value]="true" [disabled]="readonly" /></td>
    <td><input type="radio" [(ngModel)]="admissionsCriteriaViewModel.generalAdmissionCriteriaAcceptance" name="generalAdmissionCriteriaAcceptance" (change)="valuesChanged()" [value]="false" [disabled]="readonly" /></td>
  </tr>
</table>
