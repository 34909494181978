<div class="modal fade ignore-calendar-unselect" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Termin erstellen</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow: auto">
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Datum:</label>
                <div>
                  {{displaySelectedDate}}
                </div>
              </div>
              <div class="form-group">
                <label>Studiengang</label>
                <div>
                  <!--<select class="form-control" name="selectedMeetingOptions" [(ngModel)]="this.appointmentComposition.subjectId">-->
                    <!--<option *ngIf="!this.appointmentComposition.subjectId" [value]="null" selected>Bitte wählen.</option>-->
                    <!--<option *ngFor="let subject of filteredSubjects"-->
                      <!--value="{{subject.id}}">-->
                      <!--{{subject.studyBranchName}} ({{subject.hostName}})-->
                    <!--</option>-->
                  <!--</select>-->
                  <ss-multiselect-dropdown extended [autoCloseAfterMilliseconds]=1500
                                          name="studyBranches"
                                          [options]="selectableStudybranches"
                                          [(ngModel)]="this.subjectIds"
                                          (ngModelChange)="onSeriesChange($event)"
                                          >
                  </ss-multiselect-dropdown>
                </div>
              </div>
              <div class="form-group">
                <label>Meetingoptionen</label>
                <div>
                  <ss-multiselect-dropdown extended [autoCloseAfterMilliseconds]=1500
                    name="meetingOptions"
                    [options]="selectableMeetingOptions"
                    [(ngModel)]="appointmentComposition.MeetingTypeOptions"
                    (ngModelChange)="onSeriesChange($event)">
                  </ss-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="series.enabled">
            <div class="col-md-12">
              <div class="panel panel-primary">
                <div class="panel-heading">
                <!-- <div class="panel-heading" (click)="showSerieEntry = !showSerieEntry"> -->
                  Serieneintrag (ab obigem Datum)
                  <input type="checkbox" class="pull-right" name="serieEntry" [(ngModel)]="series.selected" (ngModelChange)="onSeriesChange($event)"/>
                </div>
                <div class="panel-body" [hidden]="!series.selected">

                  <div class="form-group">
                    <input type="radio" name="series-type" [(ngModel)]="series.type" [value]="'workday'" (ngModelChange)="onSeriesChange($event)"/> Jeden Arbeitstag von {{displaySelectedTime}} <br/>
                    <input type="radio" name="series-type" [(ngModel)]="series.type" [value]="'weekly'" (ngModelChange)="onSeriesChange($event)"/> Alle <input type="number" min="1" name="weekly-frequency" [(ngModel)]="series.frequency" (ngModelChange)="onSeriesChangeFrequency($event)"> Wochen(n) am {{displaySelectedWeekDay}}
                  </div>

                  <div class="form-group">
                    <app-datepicker name="endDate" [prefix]="'Endet am'" [(ngModel)]="series.endDate" [hideClear]="true" (ngModelChange)="onSeriesChange($event)"></app-datepicker>
                  </div>

                  <div class="alert alert-info">
                    <span class="badge" style="background-color: #31708f;">
                      <i class="fa fa-info"></i>
                    </span>
                    Es werden {{series.count}} Termine erstellt.
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <div class="row" *ngIf="creating">
              <div class="col-md-12">
                <app-loading-indicator [size]="'35px'" [margin]="'0.5em auto'"></app-loading-indicator>
              </div>
            </div>
            <div class="row" *ngIf="!creating">
              <div class="col-md-6">
                <div class="form-group pull-left">
                  <button class="btn btn-primary" (click)="createAppointment()" [disabled]="(!this.subjectIds || this.subjectIds.length == 0) || appointmentComposition.MeetingTypeOptions.length == 0 || series.selected && series.count === 0">
                    Termin erstellen
                  </button>
                </div>
              </div>
              <div class="col-md-6 pull-right">
                <div class="form-group">
                  <button class="btn btn-default" (click)="createAppointmentModal.hide()" >
                    Abbrechen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
