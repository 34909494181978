<div fxFill fxLayout="column">
  <div fxFlex="55px">
    <app-header></app-header>
  </div>

  <div fxFlex>
    <div style="padding-top:55px;">

      <router-outlet></router-outlet>
      <!--<div style="padding-top:55px;"></div>-->
    </div>
  </div>
</div>

