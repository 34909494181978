<div fxLayout="row" fxFill style="background-color:#CDDEFF;">
  <div fxFlex="17" appAutoWindowHeight [marginTop]="55" style="background-color:white;">
    <app-list [studyBranchSubjects]="studyBranchSubjects" (createRequested)="anlassId = null; confirmNew.showModal();"></app-list>
  </div>
  <div fxFlex appAutoWindowHeight [marginTop]="56" style="padding:0rem 3rem;background-color: white;">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>


<app-ngx-confirmation-modal #confirmNew
  [title]="'Neuen Studiengang erfassen'"
  (onEventConfirmed)="create(anlassId, profilId)"
  (onEventDeclined)="anlassId = null"
  [confirmDisabled]="!anlassId">
  <app-ngx-confirmation-modal-content>

    <div>
      <input type="text" class="form-control" [(ngModel)]="anlassId" placeholder="EventoId Studiengang"/>
      <input type="text" class="form-control" [(ngModel)]="profilId" placeholder="EventoId Profil"/>
    </div>
    <br/>

  </app-ngx-confirmation-modal-content>

</app-ngx-confirmation-modal>
