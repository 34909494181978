<div class="row">
  <div class="col-md-6">
    <div style="margin: 2rem 0rem;">
      <div class="panel panel-default">
        <div class="panel-heading">
          {{title}}
          <span class="badge pull-right">{{getTotalHosts()}}</span>
        </div>
        <table class="table table-bordered table-condensed table-responsive">
          <thead>
          <tr>
            <td style="width: 100%;">
              <app-employee-select [(ngModel)]="selectedHost"
                                   [placeholder]="'PleaseSelectDiscussionPartner' | translate"></app-employee-select>
            </td>
            <td style="width: 1px;">
              <button type="button" class="btn btn-default btn-xs" (click)="addItem(selectedHost)" [disabled]="noText"><i
                class="fa fa-plus"></i></button>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let host of hosts | orderByImpure: 'text'" class="selectableEmployee"
              [ngClass]="{'active': (editingHost) && (editingHost.id === host.id) }">
            <ng-container *ngIf="!host.deleted">
              <td (click)="editHost(host)">{{host.text}}</td>
              <td style="width: 1px;" class="text-center">
                <button type="button" class="btn btn-danger btn-xs" (click)="removeCurrentItem(host)"
                        [disabled]="!host.editable"><i class="fa fa-trash"></i></button>
              </td>
            </ng-container>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div style="margin: 2rem 0rem;">
      <app-host-signature-edit #signatureComponent *ngIf="editingHost" [employee]="editingHost" ></app-host-signature-edit>
    </div>
  </div>
</div>
