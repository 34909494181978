<div style="margin: 2rem 0rem;">
  <div class="panel panel-default">
    <div class="panel-heading">
      {{'UiTopics' | translate}}
      <span class="badge pull-right">{{activeTopics.length}}</span>
    </div>
    <table class="table table-bordered table-condensed table-responsive">
      <thead>
      <tr>
        <td style="width: 100%;">
          <input type="text" class="form-control" [(ngModel)]="topicItem"/>
        </td>
        <td style="width: 1px;">
          <button type="button" class="btn btn-default btn-xs" (click)="addItem(topicItem)" [disabled]="noText"><i
            class="fa fa-plus"></i></button>
        </td>
      </tr>
      </thead>
      <tbody [dragula]='"bag-one"' [dragulaModel]='activeTopics' >
      <tr *ngFor="let topic of activeTopics" [attr.data-id]="topic.id">
        <ng-container>
          <td>
            <div class="clearfix">
              <div style="width: 3%;float:left;" >
                <span class="grab-me" style="height:30px;width:30px;cursor: move;color:gray">
                  <i class="fa fa-bars grab-me" aria-hidden="true" style="cursor: hand;"></i>
                </span>
              </div>
              <div style="float:left;color:black; min-width:97%; ">
                <inline-editor type="text" [(ngModel)]="topic.topic" (onSave)="valueChanged()" size="30"></inline-editor>
              </div>
            </div>
          </td>
          <td style="width: 1px;" class="text-center">
            <button type="button" class="btn btn-danger btn-xs"
                    (click)="removeCurrentItem(topic)">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>
</div>
