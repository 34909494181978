<table class="table table-responsive table-striped table-bordered">
  <thead>
  <tr>
    <td>{{'AllowanceToModule' | translate}}</td>
    <td>{{'ModuleShort' | translate}}</td>
    <td>{{'ECTS' | translate}}</td>
    <td>{{'Concessions' | translate}}</td>
    <td>{{'AcquiredAt' | translate}}</td>
    <td></td>
  </tr>
  </thead>
  <tbody>
  <!-- displaying already added rows -->
  <tr *ngFor="let sAcceptance of suitabilityAdvanceAcceptanceViewModel.studentAdvanceAcceptances">
    <td>{{sAcceptance.moduleName}}</td>
    <td>{{sAcceptance.moduleNameShort}}</td>
    <td>{{sAcceptance.ects}}</td>
    <td>
        <input type="text" [(ngModel)]="sAcceptance.advance" class="form-control" (change)="valuesChanged()"/>
    </td>
    <td>
        <input type="text" [(ngModel)]="sAcceptance.aquired" class="form-control" (change)="valuesChanged()"/>        
    </td>
    <td><button class="btn btn-danger" (click)="deleteAcceptance(sAcceptance)"><i class="fa fa-times"></i></button></td>
  </tr>
  </tbody>
</table>
<div *ngIf="!readonly">
  <div class="row" *ngIf="msc.selectables.length > 0">
    <div class="col-md-10">
      <ng-select
        #mSelected
        [active]="msc.active"
        [allowClear]="'true'"
        [items]="msc.selectables"
        [placeholder]="'SearchForModulePlaceholder' | translate"
        (selected)="msc.select($event)"
        (removed)="msc.reset()"
      ></ng-select>
    </div>
    <div class="col-md-1 offset-md-1">
      <button class="btn btn-primary" (click)="addFromControl(msc)" [disabled]="!msc.hasSelected">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</div>


