

<!-- if an appointment was selected -->
<div class="row" *ngIf="selectedStudyAppointment && !appointmentBooked">
  <div class="col-md-8 col-md-offset-2" style="background-color: white;padding-bottom: 36px;padding-left:36px;padding-right:36px;">
    <form>
      <div>
        <button class="btn" (click)="unselectedStudyAppointment()">{{'Back' | translate}}</button>
        <br />
        <br />
      </div>
      <div class="form-group">
        <label>{{'Date' | translate}}</label>
        <div>{{selectedStudyAppointment.start | datex:  'DD.MM.YYYY'}}</div>
      </div>
      <div class="form-group">
        <label>{{'Time' | translate}}</label>
        <div>{{selectedStudyAppointment.start | datex:  'HH:mm'}} - {{selectedStudyAppointment.end | datex: 'HH:mm'}}</div>
        <span *ngIf="isDifferent(selectedStudyAppointment.start)">
          <br/> {{'YourTime' | translate }}: {{selectedStudyAppointment.start | datex: 'DD.MM.YYYY HH:mm': true}} - {{selectedStudyAppointment.end | datex: 'HH:mm': true}}
        </span>
      </div>
      <div class="form-group">
        <label>{{'MeetingOptions' | translate}}</label>
        <div>
          <select class="form-control" name="selectedMeetingOption" [(ngModel)]="appointmentComposition.meetingtype">
            <option *ngFor="let meetingOption of meetingOptions" value="{{meetingOption.id}}">{{meetingOption.label}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group" *ngIf="needsMeetingInfo()">
        <label>{{'SkypeName' | translate}}</label>
        <div>
          <input type="text" class="form-control" name="meetingInfo" [(ngModel)]="appointmentComposition.meetinginfo"/>
        </div>
      </div>
      <div class="form-group">
        <label>{{'PhoneNr' | translate}}</label>
        <div>
          <input type="text" class="form-control" name="studentTelefonNr" [(ngModel)]="appointmentComposition.studentTelefonNr"/>
        </div>
      </div>

      <div>
        <button type="button" (click)="bookAppointmentConfirm()" class="btn btn-primary" [disabled]="!canBook()">{{'Book' | translate}}</button>
      </div>
    </form>
  </div>
</div>


<!-- If the booking was made and was succesfully booked -->
<div class="row" *ngIf="appointmentBooked">
  <div class="col-md-8 col-md-offset-2" style="background-color: white;padding-bottom: 36px;padding-left:36px;padding-right:36px;">
    <p>
      <span [innerHTML]="'ThanksForBookingText' | translate"></span><br /><br />
    </p>
    <br />
    <br />
    <!-- Booking details -->
    <table class="table table-responsive table-bordered" style="border-left:0;border-right:0;">
      <tr>
        <td><label>{{'StudyBranch' | translate}}</label></td>
        <td>{{studentAppointmentsData.subject.studyBranchName}}</td>
      </tr>
      <tr>
        <td><label>{{'Date' | translate}}</label></td>
        <td>{{appointmentComposition.start | datex: 'DD.MM.YYYY': 'UTC'}}</td>
      </tr>
      <tr>
        <td><label>{{'Time' | translate}}</label></td>
        <td>
          {{appointmentComposition.start | datex: 'HH:mm'}} - {{appointmentComposition.end | datex: 'HH:mm'}}
          <span *ngIf="isDifferent(appointmentComposition.start)">
            <br/> {{'YourTime' | translate }}: {{appointmentComposition.start | datex: 'DD.MM.YYYY HH:mm': true}} - {{appointmentComposition.end | datex: 'HH:mm': true}}
          </span>
        </td>
      </tr>
      <tr>
        <td><label>{{'Meetingstype' | translate}}</label></td>
        <td>{{ meetingOptionsService.getMeetingOptionById(appointmentComposition.meetingtype).label }}
        </td>
      </tr>
      <tr *ngIf="appointmentComposition.meetinginfo">
        <td><label>{{'SkypeName' | translate}}</label></td>
        <td>{{appointmentComposition.meetinginfo}}</td>
      </tr>
      <tr>
        <td><label>{{'PhoneNr' | translate}}</label></td>
        <td>{{appointmentComposition.studentTelefonNr || '-'}}</td>
      </tr>
    </table>
  </div>
</div>

<app-confirmation-modal #confirmationModal (onEventConfirmed)="bookAppointment()" class="modal-center"
  [withoutHeader]="true"
  [body]="'BookAppointmentConfirm' | translate"
  >
</app-confirmation-modal>