<div fxFill fxLayout="row" style="display:flex; background-color: white;">
  <!-- Left sidebar -->
  <div fxFlex="0 0 394px" style="min-width:320px; width:420px;" appAutoWindowHeight [marginTop]="55"
       *ngIf="canfilterStudyBranch || hasNavigateCalendar || canCreate">
    <!-- Left sidebar -->
    <div>
      <div class="text-center"
           style="font-size: 18px;margin-top: 1rem;margin-left: 1rem;margin-right: 1rem;padding: 0.4rem;background-color:white;border: 1px solid #DEDEDE;border-radius: 3px;">
        {{configInfo}}
      </div>
      <div style="padding: 1rem">
        <!-- calendar picker -->
        <datepicker [(ngModel)]="date" *ngIf="hasNavigateCalendar"
                    extended
                    [selection]="getView()"
                    [appointments]="appointments"
        >
        </datepicker>
        <!--defaults-->
        <!--
        [showWeeks]="true"
        [startingDay]="1"
        [formatDayHeader]="'dd'"
        -->
        <!-- Create appointment button -->
        <button *ngIf="canCreate"
                type="button"
                class="btn btn-primary btn-block ignore-calendar-unselect"
                (click)="openCreateAppointmentModal()"
                [disabled]="!canCreateAppointment()">
          Termin erstellen
        </button>
        <br/>
        <!-- list of subjects -->
        <app-overview-filter-panel *ngIf="canfilterStudyBranch"
                                   (subjectSelectedEvent)="updateStudyBranchFilter()"
                                   [selectableSubjects]="selectableSubjects"
        >
        </app-overview-filter-panel>

        <div style="margin-top: 40px;">
          <ng-content select="[belowStudyBranch]"></ng-content>
        </div>

      </div>
    </div>
  </div>

  <!-- Main Content -->
  <div fxFlex style="background-color: #CDDEFF; width:100%;" >
    <!-- User info -->
    <div class="user-info">
      {{'Welcome' | translate}} {{user.firstName}} {{user.name}}
    </div>
    <div>
      <ng-content select="[aboveFilter]"></ng-content>
      <div style="padding: .2rem 1rem 1rem 2rem;margin: 1rem;background-color: white;">
        <h4><strong>{{'Filter' | translate}}</strong></h4>
        <div class="row">
          <!--          <div class="col-md-2 col-sm-12 col-xs-12" *ngIf="titleFilter.active">
                      <label>Titel</label>
                      <div>
                        <input type="text" class="form-control" [(ngModel)]="titleFilter.value"/>
                      </div>
                    </div>-->
          <div class="col-md-2 col-sm-6 col-xs-12" *ngIf="statusFilter.active">
            <label>Status</label>
            <div>
              <ss-multiselect-dropdown extended [autoCloseAfterMilliseconds]=1500
                name="statusOptions"
                [settings]="selectionSettings"
                [options]="selectableStatuses"
                [(ngModel)]="statusFilter.value">
              </ss-multiselect-dropdown>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12" *ngIf="meetingFilter.active">
            <label>{{'MeetingOptions' | translate}}</label>
            <div>
              <ss-multiselect-dropdown extended [autoCloseAfterMilliseconds]=1500
                name="meetingOptions"
                [settings]="selectionSettings"
                [options]="selectableMeetingOptions"
                [(ngModel)]="meetingFilter.value">
              </ss-multiselect-dropdown>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12" *ngIf="meetingFilter.active || statusFilter.active">
            <label>{{'Action' | translate}}</label>
            <div>
              <button class="btn btn-primary" (click)="resetFilter()">{{'RemoveFilter' | translate}}</button>
            </div>
          </div>
          <div class="col-md-2 col-sm-6 col-xs-12" *ngIf="meetingFilter.active && selectableEmployees.length > 1 || isDelegate()">
            <label>{{'SubstitutionFor' | translate}}</label>
            <div>
              <ss-multiselect-dropdown extended [autoCloseAfterMilliseconds]=1500
                name="employees"
                [disabled] = "employeeFilterFixed"
                [settings]="selectionSettingsEmployee"
                [options]="selectableEmployees"
                [(ngModel)]="employeeFilter.value">
              </ss-multiselect-dropdown>
            </div>
          </div>
          <ng-content select="[inFilter]"></ng-content>
        </div>
      </div>
    </div>
    <div appAutoWindowHeight [marginTop]="255" class="calendar-wrapper" style="margin:1rem;">
        <ng-content></ng-content>
        <app-calendar-overview></app-calendar-overview>
    </div>
  </div>
</div>

<app-appointment-composition [startDateSelected]="myCalendar.startDateSelected"
                             [endDateSelected]="myCalendar.endDateSelected"></app-appointment-composition>

<app-appointment-manage></app-appointment-manage>
