<!-- Default layout for usage in multiple projects. The remove a part, just delete it from the html -->
<div fxFill fxLayout="row">
  <!-- Main Content -->
  <div fxFlex style="padding:3rem; width:100%;overflow:auto;">

    <!-- if there are bookable appointments -->
    <app-appointment-booking-list
      *ngIf="studentAppointmentsData && bookedAppointment == null && confirmedAppointment == null"
      [_studentAppointmentsData]="studentAppointmentsData"></app-appointment-booking-list>

    <!-- if an appointment already has been booked but the student is waiting for confirmation -->
    <app-appointment-already-booked *ngIf="bookedAppointment != null && !appointmentPassed"
                                    [bookedAppointment]="bookedAppointment"
                                    [_studentAppointmentsData]="studentAppointmentsData"></app-appointment-already-booked>

    <!-- if the appointment is confirmed but NOT held -->
    <app-appoinment-confirmed *ngIf="appointmentConfirmed && !appointmentPassed"
                              [studentAppointmentsData]="studentAppointmentsData"
                              [confirmedAppointment]="confirmedAppointment"></app-appoinment-confirmed>

    <!-- if the appointment is confirmed AND held -->
    <app-appointment-held *ngIf="appointmentPassed"
                          [studentAppointmentsData]="studentAppointmentsData"
                          [confirmedAppointment]="confirmedAppointment"
    >

    </app-appointment-held>

    <!--<app-loading-indicator *ngIf="(confirmedAppointment == null) "></app-loading-indicator>-->
  </div>
</div>
