<div class="modal-body" appAutoWindowHeight [marginTop]="267">
  <div class="tabDiv">
    <div style="overflow-y: auto;">
      <table class="table table-responsive" style="width:100%; ">
        <thead>
        <tr>
          <th style="width: 40px;"></th>
          <th>File</th>
          <th style="width: 100px;">{{'Filesize' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let doc of documents">
          <td>
            <div class="btn btn-sm btn-default" (click)="download(doc)">
              <i class="fa fa-download"></i>
            </div>
          </td>
          <td (click)="download(doc)">
            {{doc.name}}
          </td>
          <td style="text-align: right;">{{sizeforUi(doc)}}</td>
        </tr>
        <tr *ngIf="documents === undefined">
          <td colspan="3" class="text-center">
              <app-loading-indicator [size]="'35px'" [margin]="'0.5em auto'"></app-loading-indicator>
          </td>
        </tr>
        <tr *ngIf="documents && documents.length == 0">
          <td>
          </td>
          <td>
            {{'NoFilesFound' | translate}}
          </td>
          <td style="text-align: right;"></td>
        </tr>
        <tr *ngIf="documents === null">
          <td>
          </td>
          <td>
            {{'RequestError' | translate}}
          </td>
          <td style="text-align: right;"></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default" (click)="this.appointmentManageService.modal.emit(null)">
    {{'Close' | translate}}
  </button>
</div>
