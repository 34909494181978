<div class="text-center">
  <button class="btn btn-primary btn-xs" (click)="confirmResend()" *ngIf="canSend">
    <i class="fa fa-envelope-o"></i>
  </button>
</div>

<app-confirmation-modal #confirmationModal (onEventConfirmed)="resend()"
  [withoutHeader]="true"
  [body]="getConfirmMsg()"
  >
</app-confirmation-modal>