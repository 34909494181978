<div class="row">
  <div class="col-md-6">
    <div style="margin: 2rem 0rem;">
      <div>
        <table class="table table-bordered table-responsive">
          <tbody>
          <tr>
            <td style="width: 300px;">{{'Number' | translate}}</td>
            <td>
              <!-- {{studyBranch.number}} -->
              <input type="text" class="form-control" [(ngModel)]="studyBranch.number" (change)="valueChanged()"/>
            </td>
          </tr>
          <tr>
            <td>{{'Name' | translate}}</td>
            <td>
              <!-- {{studyBranch.name}} -->
              <input type="text" class="form-control" [(ngModel)]="studyBranch.name" (change)="valueChanged()"/>
            </td>
          </tr>
          <tr>
            <td>{{'Duration' | translate}}</td>
            <td>
              <select class="form-control" [(ngModel)]="studyBranch.duration" (change)="valueChanged()">
                <option value="30">30</option>
                <option value="60">60</option>
              </select>
          </tr>
          <tr>
            <td>
              {{'ProgramLanguage' | translate}}
              <i class="badge badege-info fa fa-info" (click)="toggleText = !toggleText">
              </i>
              <small *ngIf="toggleText"><br/>Aktuell ist dies nur bei BF relevant, da hierbei die Unterscheidung des gleichen Studienganges (EventoId) über den Zusatz ' INT' bei der Studienform-Wahl der Stundenten (Anmeldungdetails) ermöglicht wird, und somit die Studenten getrennt werden können.
                <br/>Bei allen anderen, die <b>nicht</b> ' INT' in der Studienform haben, bitte belassen.
              </small>
            </td>
            <td>
              <select class="form-control" [(ngModel)]="studyBranch.programLanguageCode" (change)="valueChanged()">
                <option value="de">-</option>
                <option value="en">INT</option>
              </select>
          </tr>
          <tr>
            <td>{{'CorrespondenceLanguage' | translate}}</td>
            <td>
              <select class="form-control" [(ngModel)]="studyBranch.languageCode" (change)="valueChanged()">
                <option value="de">de</option>
                <option value="en">en</option>
              </select>
          </tr>
          <tr>
            <td>{{'ClerkEmail' | translate}}</td>
            <td>
              <input type="text" class="form-control" [(ngModel)]="studyBranch.saBeEmail" (change)="valueChanged()"/>
          </tr>
          <tr>
            <td style="width: 300px;">{{'Color' | translate}}</td>
            <td>
              <input [(colorPicker)]="color" [style.background]="color" (change)="valueChanged()"/>
            </td>
          </tr>
          <tr>
            <td style="width: 300px;">{{'Aktiv' | translate}}</td>
            <td>
              <input type="checkbox" [(ngModel)]="studyBranch.active" (change)="valueChanged()"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div style="margin: 2rem 0rem;">
      <div>
        <table class="table table-bordered table-responsive">
          <tbody>

          <tr>
            <td>EventoId Studiengang</td>
            <td>EventoId Profil</td>
            <td><button type="button" class="btn btn-default" (click)="addNew()">Hinzufügen</button></td>
          </tr>
          <ng-container *ngFor="let e of studyBranch.eventoIds | orderBy: ['eventoIdAnlass']">
            <tr>
              <td>
                <ng-container *ngIf="!e.isNew">
                  {{e.eventoIdAnlass}}
                </ng-container>
                <input *ngIf="e.isNew" type="text" class="form-control" [(ngModel)]="e.eventoIdAnlass"/>
              </td>
              <td>
                <ng-container *ngIf="!e.isNew">
                  {{e.eventoIdProfil}}
                </ng-container>
                <input *ngIf="e.isNew" type="text" class="form-control" [(ngModel)]="e.eventoIdProfil"/>
              </td>
              <td>
                <button *ngIf="e.isNew" type="button" class="btn btn-success btn-xs" (click)="save(e)" [disabled]="e.invalid || changing"><i class="fa fa-save"></i></button>
                <button *ngIf="e.isNew" type="button" class="btn btn-warning btn-xs" (click)="remove(e)" [disabled]="changing"><i class="fa fa-trash"></i></button>
                <button *ngIf="!e.isNew" type="button" class="btn btn-danger btn-xs" (click)="markedForDeletion = e; confirmDelete.showModal();" [disabled]="e.invalid || changing"><i class="fa fa-trash"></i></button>
                <button *ngIf="!e.isNew" type="button" class="btn btn-info" (click)="checkName(e)" [disabled]="e.checking"><i class="fa fa-search"></i> Name in Evento ? </button>
                <div *ngIf="e.checkedName">{{ e.checkedName}} </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <app-topics-edit [topics]="studyBranch.topics" (topicsChanged)="setTopics($event)"></app-topics-edit>
  </div>
  <div class="col-md-6">
    <app-places-edit [places]="studyBranch.places" (placesChanged)="setPlaces($event)"></app-places-edit>
  </div>
</div>

<app-ngx-confirmation-modal #confirmDelete
  [title]="'Löschen ?'"
  (onEventConfirmed)="delete(markedForDeletion);"
  (onEventDeclined)="markedForDeletion = null"
  [confirmDisabled]="!markedForDeletion">
  <app-ngx-confirmation-modal-content>

    <div>
        Löschen des Eintrags mit EventoId Studiengang {{markedForDeletion?.eventoIdAnlass}} 
        <ng-container *ngIf="markedForDeletion?.eventoIdProfil">
          und mit EventoId Profil {{markedForDeletion?.eventoIdProfil}}
        </ng-container>
        ?
    </div>
    <br/>

  </app-ngx-confirmation-modal-content>

</app-ngx-confirmation-modal>