<app-appointment-overview [includeEmployeePid]=false>
  <div class="row" *ngIf="canDelegate()" style="margin: 1rem 1rem 0rem 1rem;" belowStudyBranch>
    <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 0px !important;">
      <div class="form-group" style="margin-bottom: 0px;">
        <div class="panel panel-primary" style="margin-bottom: 0px;">
          <div class="panel-heading">
            {{ "MyDelegate" | translate}}
          </div>
          <div class="panel-body">
            
            <div class="input-group">
              <div class="input-group-btn">
                <div class="btn btn-default" 
                  [ngClass]="{'btn-warning': changed, 'btn-success': saved, 'btn-danger': error}"
                  (click)="saveChanged()">
                  <i class="fa fa-save"></i>
                </div>
              </div>
              <ss-multiselect-dropdown 
                extended [autoCloseAfterMilliseconds]=1500 
                style="overflow: visible;"
                name="delegate" 
                [settings]="selectionSettings" 
                [options]="selectableEmployees"
                [title]="selectedOptionText"
                [(ngModel)]="currentDelegate">
              </ss-multiselect-dropdown>
              <!-- <div class="input-group-addon">
                <input type="checkbox" [(ngModel)]="informDelegate"/>
              </div> -->
              <div class="input-group-btn">
                <button class="btn btn-default" 
                  style="border-radius: 0px;" 
                  [disabled]="!hasDelegate()"
                  (click)="clearDelegate()">
                  <i class="fa fa-remove"></i>
                </button>
              </div>
              <div class="input-group-btn">
                <div [title]="'DelegateInformInfo' | translate"
                  class="btn btn-default fa "
                  [ngClass]="{'fa-calendar-check-o': informDelegate, 'fa-calendar-times-o': !informDelegate}"
                  (click)="informDelegate = !informDelegate; changed=true;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-appointment-overview>