<!--<button type="button" class="btn btn-primary" (click)="openModal(template)">Create template modal</button>-->
<button type="button" class="btn btn-default btn-xs" (click)="openModal(template)">
  <i class="fa fa-pencil" aria-hidden="true"></i></button>
<template #template  >
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'OverallRating' | translate}} {{'Editing' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{'HasOverallRating' | translate}}
    <br />
    <input type="radio" [(ngModel)]="suitabilityOverallRatingEditViewModel.hasOverallAssessment" (change)="valuesChanged()"  [value]="true" /> {{'Yes' | translate}}<br />
    <input type="radio" [(ngModel)]="suitabilityOverallRatingEditViewModel.hasOverallAssessment" (change)="valuesChanged()" [value]="false" /> {{'No' | translate}}
  </div>
</template>
