<!--<button type="button" class="btn btn-primary" (click)="openModal(template)">Create template modal</button>-->
<button type="button" class="btn btn-default btn-xs" (click)="openModal(template)">
  <i class="fa fa-pencil" aria-hidden="true"></i></button>
<template #template  >
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'AdmissionCriteria' | translate}} {{'Editing' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!--<table class="table table-bordered table-striped table-responsive">-->
      <!--<tr>-->
        <!--<td></td>-->
        <!--<td></td>-->
      <!--</tr>-->
      <!--<tr *ngFor="let admissionCriteria of admissionsCriteriaViewModel">-->
        <!--<td>{{admissionCriteria.criteria}}</td>-->
        <!--<td><button class="btn btn-danger btn-xs" (click)="deleteAdmissionCriteria(admissionCriteria)"><i class="fa fa-times"></i></button> </td>-->
      <!--</tr>-->

      <!--<tr>-->
        <!--<td>-->
          <!--<input type="text" class="form-control"  [(ngModel)]="admissionCriteria" />-->
        <!--</td>-->
        <!--<td colspan="2">-->
          <!--<button class="btn btn-primary btn-block" (click)="addNewCriteria(admissionCriteria)">Add</button>-->
        <!--</td>-->
      <!--</tr>-->
    <!--</table>-->

    <table class="table table-bordered table-condensed table-responsive">
      <thead>
      <tr>
        <td style="width: 100%;">
          <input type="text" class="form-control" [(ngModel)]="admissionCriteria"/>
        </td>
        <td style="width: 1px;">
          <button type="button" class="btn btn-default btn-xs" (click)="addNewCriteria(admissionCriteria)"><i
            class="fa fa-plus"></i></button>
        </td>
      </tr>
      </thead>
      <tbody [dragula]='"bag-one"' [dragulaModel]='admissionsCriteriaViewModel' >
      <tr *ngFor="let admissionCriteria of admissionsCriteriaViewModel">
        <ng-container>
          <td>
            <div class="clearfix">
              <div style="width: 3%;float:left;" >
                <span class="grab-me" style="height:30px;width:30px;cursor: move;color:gray">
                  <i class="fa fa-bars grab-me" aria-hidden="true" style="cursor: hand;"></i>
                </span>
              </div>
              <div style="float:left;color:black; min-width:97%; ">
                <inline-editor type="text" [(ngModel)]="admissionCriteria.criteria"  size="30" (onSave)="valuesChanged()"></inline-editor>
              </div>
            </div>
          </td>

          <td style="width: 1px;" class="text-center">
            <button type="button" class="btn btn-danger btn-xs"
                    (click)="deleteAdmissionCriteria(admissionCriteria)">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>
  <!--<div class="modal-footer">-->
    <!--<div>-->
      <!--<button class="btn btn-primary" (click)="valuesChanged()">{{'Save' | translate}}</button>-->
    <!--</div>-->
  <!--</div>-->
</template>
