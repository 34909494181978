<div fxLayout="row"  style="background-color:#CDDEFF;">
<div fxFlex appAutoWindowHeight [marginTop]="431" style="background-color: white;">
  <fieldset [disabled]="isRated()" [attr.readonly]="isRated()">
  <!-- general section -->
    <app-suitability-section *ngIf="studentSuitability">
      <app-suitability-title>
        {{'RatingsFormOf' | translate}} {{suitabilityGeneralViewModel.studentInfo}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-general [suitabilityGeneralViewModel]="suitabilityGeneralViewModel"></app-suitability-general>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->

    <!-- admission section -->
    <app-suitability-section *ngIf="studentSuitability">
      <app-suitability-title>
        {{'AdmissionCriteria' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-admission-criteria #admissionCriteria [readonly]="isRated()" [admissionsCriteriaViewModel]="admissionsCriteriaViewModel" (admissionsCriteriaViewModelChanged)="prepareSuitabilityForSave()"></app-suitability-admission-criteria>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end admission section -->

    <!-- handicap section -->
    <app-suitability-section *ngIf="studentSuitability">
      <app-suitability-title>
        {{'DisadvantageCompensation' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-handicap #suitabilityHandicap [readonly]="isRated()" [suitabilityHandicapViewModel]="suitabilityHandicapViewmodel" (suitabilityHandicapViewModelChanged)="prepareSuitabilityForSave()"></app-suitability-handicap>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->

    <!-- languages section -->
    <app-suitability-section *ngIf="studentSuitability">
      <app-suitability-title>
        {{'LanguageKnowhow' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-languages [suitabilityLanguagesViewModel]="suitabilityLanguagesViewModel" (suitabilityLanguagesViewModelChanged)="prepareSuitabilityForSave()"></app-suitability-languages>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->



    <!-- Studyform section -->
    <app-suitability-section *ngIf="studentSuitability">
      <app-suitability-title>
        {{'StudyForm' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-studyform #suitabilityStudyform [suitabilityStudyformViewModel]="suitabilityStudyformViewModel" (suitabilityStudyformViewModelChanged)="prepareSuitabilityForSave()"></app-suitability-studyform>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->

    <!-- overall qualifications section -->
    <app-suitability-section *ngIf="studentSuitability">
      <app-suitability-title>
        {{'QualificationsDetails' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-qualifications [readonly]="isRated()" [suitabilityQualificationsViewModel]="suitabilityQualificationsViewModel" (suitabilityQualificationsViewModelChanged)="prepareSuitabilityForSave()"></app-suitability-qualifications>
      </app-suitability-content>
    </app-suitability-section>

    <!-- overall qualifications section -->
    <app-suitability-section *ngIf="studentSuitability">
      <app-suitability-title>
        {{'AdvanceAcceptances' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-advance-acceptance [readonly]="isRated()" [suitabilityAdvanceAcceptanceViewModel]="suitabilityAdvanceAcceptanceViewModel" (suitabilityAdvanceAcceptanceViewModelChanged)="prepareSuitabilityForSave()"></app-suitability-advance-acceptance>
      </app-suitability-content>
    </app-suitability-section>

    <!-- overall rating notes section -->
    <app-suitability-section *ngIf="studentSuitability && suitabilityOverallRatingViewModel.id">
      <app-suitability-title>
        {{'OverallRating' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-overall-rating #suitabilityOverallRating [suitabilityOverallRatingViewModel]="suitabilityOverallRatingViewModel" (suitabilityOverallRatingViewModelChanged)="prepareSuitabilityForSave()"></app-suitability-overall-rating>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->

    <!-- Acceptance section -->
    <app-suitability-section *ngIf="studentSuitability">
      <app-suitability-title>
        {{'MessageToStudentService' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-student-acceptance #suitabilityStudentAcceptance [suitabilityStudentAcceptanceViewModel]="suitabilityStudentAcceptanceViewModel" (suitabilityStudentAcceptanceViewModelChanged)="prepareSuitabilityForSave()"></app-suitability-student-acceptance>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->

    <!-- Additional notes section -->
    <app-suitability-section *ngIf="studentSuitability">
      <app-suitability-title>
        {{'AdditionalComments' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-additional-notes [suitabilityAdditionalNotesViewModel]="suitabilityAdditionalNotesViewModel" (suitabilityAdditionalNotesViewModelChanged)="prepareSuitabilityForSave()"></app-suitability-additional-notes>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->
  </fieldset>
  </div>
</div>
