<!--<button type="button" class="btn btn-default btn-xs" (click)="confirmationModal.show()"><i class="fa fa-plus"></i></button>-->
<ng-content (click)="confirmationModal.show()"></ng-content>
<div bsModal #confirmationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog mod  al-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{title | translate}}</h4>
        <button type="button" class="close pull-right" (click)="confirmationModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content select="app-ngx-confirmation-modal-content"></ng-content>
      </div>
      <div class="modal-footer">
        <button [ngClass]="declineStyle ? declineStyle : 'btn btn-warning'" type="button" (click)="decline()">
          <ng-container *ngIf="!declineText">{{ "Cancel" | translate }}</ng-container>
          <ng-container *ngIf="declineText">{{ declineText }}</ng-container>
        </button>
        <button [ngClass]="confirmStyle ? confirmStyle : 'btn btn-primary'" type="button" (click)="confirm()" [disabled]="confirmDisabled">
          <ng-container *ngIf="!confirmText">{{ "Ok" | translate }}</ng-container>
          <ng-container *ngIf="confirmText">{{ confirmText }}</ng-container>
        </button>
      </div><!--/modal-footer-->
    </div>
  </div>
</div>
