<div style="margin: 2rem 0rem;">
  <div class="panel panel-default">
    <div class="panel-heading">
      {{'UiPlaces' | translate}}
      <span class="badge pull-right">{{getTotalPlaces()}}</span>
    </div>
    <table class="table table-bordered table-condensed table-responsive">
      <thead>
      <tr>
        <td style="width: 100%;">
          <!-- <input type="text" class="form-control" [(ngModel)]="placeItem"/> -->
          <textarea class="form-control" [(ngModel)]="placeItem" rows="5"></textarea>
        </td>
        <td style="width: 1px;">
          <button type="button" class="btn btn-default btn-xs" (click)="addItem(placeItem)" [disabled]="noText"><i
            class="fa fa-plus"></i></button>
        </td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let place of places | orderBy: 'name'">
        <ng-container *ngIf="!place.deleted">
          <td>
            <inline-editor type="textarea" [(ngModel)]="place.name" (onSave)="valueChanged()"  size="300"></inline-editor>
          </td>
          <td style="width: 1px;" class="text-center">
            <button type="button" class="btn btn-danger btn-xs"
                    (click)="removeCurrentItem(place)">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>
</div>
