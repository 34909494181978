<!-- Edit button -->
<button class="btn btn-default btn-xs" (click)="show();">
  <i class="fa fa-pencil"></i>
</button>


<app-ngx-confirmation-modal #confirmChange
  [title]="'ManageRoles' | translate "
  (onEventConfirmed)="commit()"
  (onEventDeclined)="close()"
  [confirmDisabled]="!hasChanges">
  <app-ngx-confirmation-modal-content>

  <div>
    <h3>
      {{source.firstName}} {{source.lastName}} ({{source.pid}})
    </h3>
  </div>
  <br/>

  <div style="text-align: left;">
    <app-ngx-filter-table
        *ngIf="data"
        [data]="data"
        [options]="options"
        [maxRowsPerPage]="15"
        [styleClass]="'table table-bordered table-condensed table-responsive table-striped table-hover filtertable-table'">
    </app-ngx-filter-table>
  </div>

  </app-ngx-confirmation-modal-content>

</app-ngx-confirmation-modal>