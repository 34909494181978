<div class="modal-body" appAutoWindowHeight [marginTop]="267">
  <div class="tabDiv">
    <div class="row">
      <div class="col-md-12">
        <div class="well wel-sm user-info" style="margin-top:1rem;"
             [ngStyle]="hasChanges() && {'background-color': 'rgb(252, 248, 227)'}" *ngIf="appointmentObject">
          {{'Student' | translate}}: <b>{{appointmentObject.student}}</b>
          <br /><span>{{'StudyBranch' | translate}}: <b>{{appointmentObject.subjectName}}</b></span>
          <!-- last saved indicator -->
          <span class="pull-right">
            <span *ngIf="saveState">
              {{'LastSaved' | translate}}:
              <span *ngIf="saveState.lastSaved">{{saveState.lastSaved | datex: 'DD.MM.YYYY HH:mm:ss'}}</span>
              <span *ngIf="!saveState.lastSaved">---</span>
            </span>
            <app-loading-indicator *ngIf="saveState && saveState.isSaving" class="pull-right" [margin]="'0px'"
                                   [size]="'21px'"></app-loading-indicator>
          </span>
          <br />

        </div>



      </div>
    </div>
    <app-sgl-suitability-main #sglSuitabilityMain *ngIf="appointment && appointmentObject" [appointment]="appointment"
    [appointmentObject]="appointmentObject"
                              [appointmentId]="appointment.id"></app-sgl-suitability-main>
  </div>
</div>
<div class="modal-footer">
  <button class="pull-left btn btn-primary" style="margin-right: 8rem" (click)="save()" [disabled]="!canSave()" *ngIf="!isRated()">
    {{'Save' | translate}}
  </button>

  <button class="pull-left btn btn-primary" [disabled]="!canSubmit()" (click)="submit()" *ngIf="!isRated()">
    {{'Send' | translate}}
  </button>
  <info-rating-modal class="pull-left" style="margin-left:10px;" *ngIf="!isRated()"></info-rating-modal>

  <button class="btn btn-default" (click)="this.appointmentManageService.modal.emit(null)">
    {{'Close' | translate}}
  </button>
</div>
