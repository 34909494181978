<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Terminslots / Studiengang</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow: auto">
        <form>
          
          <app-loading-indicator  [margin]="'0.5em auto'" [hidden]="!loading"></app-loading-indicator>
          
          <div [hidden]="loading">

            <div class="form-group">
              <select class="form-control" name="sbSelect" [(ngModel)]="selected" placeholder="Bitte wählen.">
                <option *ngFor="let sb of listData" [ngValue]="sb">{{sb}}</option>
              </select>
            </div>

            <div > 
              <canvas baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [colors]="colors"
                [legend]="barChartLegend"
                [chartType]="barChartType"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)">
              </canvas>
            </div>
          </div>

        </form>
        
        <!-- <div class="modal-footer">
          <div class="row">
            <div class="col-md-6 pull-right">
              <div class="form-group">
                <button class="btn btn-default" (click)="staticModal.hide()">
                  Abbrechen
                </button>
              </div>
            </div>
          </div>
        </div> -->
        
      </div>
    </div>
  </div>
</div>
