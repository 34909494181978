<div class="container-fluid" [ngClass]="{'login-component' : isProduction, 'login-component-staging' : isStaging, 'login-component-local' : !isProduction && !isStaging}">
  <div class="login">
    <div class="row">
      <div class="
				 col-lg-4 col-lg-offset-4
				 col-md-6 col-md-offset-3
				 col-sm-8 col-sm-offset-2
				 col-xs-10 col-xs-offset-1 ">
        <div style="max-width: 440px; max-height: 550px; margin: auto auto;">
          <div class="col-md-12">
            <div class="logo">
              <img class="fit" src="assets/zhaw_logo_white.png"/>
            </div>
          </div>
          <div class="col-md-12 loginbox">

            <div class="banner">
              <img class="fit" src="assets/zhaw_banner_leaf.png"/>
            </div>

            <form [formGroup]="loginForm" novalidate (ngSubmit)="login(loginForm.value, loginForm.valid)">
              <div class="appname">
                {{'ApplicationName' | translate}}  {{isStaging ? 'TEST' : !isProduction ? 'local' : ''}}
              </div>
              <div class="alert alert-warning" style="margin-top: 10px;" *ngIf="submitted && (loginForm.controls.username.errors || loginForm.controls.password.errors)">
                <ul>
                  <li *ngIf="loginForm.controls.username.errors">{{'Msg_UsernameRequired' | translate}}</li>
                  <li *ngIf="loginForm.controls.password.errors">{{'Msg_PasswordRequired' | translate}}</li>

                </ul>
              </div>
              <div class="alert alert-danger" style="margin-top: 10px;" *ngIf="error" type="danger">
                {{ error | translate }}
              </div>

              <p class="text-danger">
              </p>
              <div class="form-group">
                <input name="username" type="text" placeholder="{{'Username' | translate}}"
                       class="form-control bigmargin bigInput" autofocus formControlName="username"/>
                <input name="password" type="password" placeholder="{{'Password' | translate}}"
                       class="form-control bigmargin bigInput" formControlName="password"/>
              </div>

              <div class="form-group">
                <button class="btn btn-primary btn-block btn-login" type="submit" [disabled]="submitFormDisabled">
                  {{ 'Login' | translate }}<span *ngIf="submitFormDisabled">...</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
