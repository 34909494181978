<div class="input-group">
  <span class="input-group-addon" *ngIf="prefix">{{prefix}}</span>
  <input class="form-control" style="float:none" [placeholder]="placeholder" ngx-mydatepicker
         name="date"
         [options]="options"
         #date="ngx-mydatepicker"
         [value]="value"
         (dateChanged)="onDateChanged($event)"
         (inputFieldChanged)="onInputFieldChanged($event)"
         (keydown.ArrowUp) = "arrowKeyUp($event)"
         (keydown.ArrowDown) = "arrowKeyDown($event)"
         [disabled]="disabled"
         autocomplete="off"
         [style.color]="isThisValid ? 'inherit' : 'red'" />


  <span class="input-group-btn">
      <button *ngIf="inputOptions?.enableInfinity" type="button" class="btn btn-default" style="padding:4px 10px 3px 10px;" (click)="setInfinity() " [disabled]="disabled">
            <strong style="font-weight: bold;font-size:18px;">&infin;</strong>
      </button>
      <button type="button" class="btn btn-default" (click)="date.toggleCalendar(); " [disabled]="disabled">
          <i class="glyphicon glyphicon-calendar"></i>
      </button>
      <button type="button" class="btn btn-default" (click)="date.clearDate();" [disabled]="disabled" *ngIf="!hideClear">
          <i class="glyphicon glyphicon-remove"></i>
      </button>
  </span>

</div>

