<table class="table table-bordered table-striped table-responsive">
  <tr>
    <td></td>
    <td>{{'Date' | translate}}</td>
    <td>{{'Initials' | translate}}</td>
  </tr>
  <tr>
    <td>{{'DossierReceivedDate' | translate}}</td>
    <td>{{suitabilityGeneralViewModel.sent | datex: 'DD.MM.YYYY'}}</td>
    <td></td>
  </tr>
  <tr>
    <td>{{'ApprovalDiscussionDate' | translate}}</td>
    <td>{{suitabilityGeneralViewModel.start | datex: 'DD.MM.YYYY'}}</td>
    <td>{{suitabilityGeneralViewModel.initials}}</td>
  </tr>
</table>
