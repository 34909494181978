<div fxLayout="row"  style="background-color:#CDDEFF;">
  <div fxFlex appAutoWindowHeight [marginTop]="285" style="background-color: white;">
    <!-- general section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'RatingsFormOf' | translate}} ...
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-general [suitabilityGeneralViewModel]="suitabilityGeneralViewModel"></app-suitability-general>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->
    <!-- admission section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'AdmissionCriteria' | translate}}
        <app-edit-admission-criteria-modal class="pull-right" [admissionsCriteriaViewModel]="admissionsCriteriaViewModel.admissionsCriteria" (admissionCriteriaChanged)="saveSuitabilityConfig($event)"></app-edit-admission-criteria-modal>
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-admission-criteria [admissionsCriteriaViewModel]="admissionsCriteriaViewModel"></app-suitability-admission-criteria>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end admission section -->
    <!-- handicap section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'DisadvantageCompensation' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-handicap [suitabilityHandicapViewModel]="suitabilityHandicapViewmodel"></app-suitability-handicap>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->
    <!-- languages section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'LanguageKnowhow' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-languages [suitabilityLanguagesViewModel]="suitabilityLanguagesViewModel"></app-suitability-languages>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->



    <!-- Studyform section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'StudyForm' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-studyform [suitabilityStudyformViewModel]="suitabilityStudyformViewModel"></app-suitability-studyform>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->

    <!-- overall qualifications section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'QualificationsDetails' | translate}}
        <app-edit-qualifications-modal class="pull-right" [suitabilityQualificationsViewModel]="suitabilityQualificationsViewModel" (suitabilityQualificationsViewModelChanged)="saveSuitabilityConfig($event)"></app-edit-qualifications-modal>
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-qualifications [suitabilityQualificationsViewModel]="suitabilityQualificationsViewModel"></app-suitability-qualifications>
      </app-suitability-content>
    </app-suitability-section>

    <!-- advance acceptance section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'AdvanceAcceptances' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-advance-acceptance [suitabilityAdvanceAcceptanceViewModel]="suitabilityAdvanceAcceptanceViewModel"></app-suitability-advance-acceptance>
      </app-suitability-content>
    </app-suitability-section>

    <!-- overall rating notes section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'OverallRating' | translate}}
        <app-edit-overall-rating-modal class="pull-right" [suitabilityOverallRatingEditViewModel]="suitabilityOverallRatingEditViewModel" (suitabilityOverallRatingEditViewModelChanged)="saveSuitabilityConfig($event)"></app-edit-overall-rating-modal>
      </app-suitability-title>
      <app-suitability-content *ngIf="suitabilityOverallRatingEditViewModel.hasOverallAssessment">
        <app-suitability-overall-rating  [suitabilityOverallRatingViewModel]="suitabilityOverallRatingViewModel"></app-suitability-overall-rating>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->

    <!-- Acceptance section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'MessageToStudentService' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-student-acceptance [suitabilityStudentAcceptanceViewModel]="suitabilityStudentAcceptanceViewModel"></app-suitability-student-acceptance>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->

    <!-- Additional notes section -->
    <app-suitability-section *ngIf="suitability">
      <app-suitability-title>
        {{'AdditionalComments' | translate}}
      </app-suitability-title>
      <app-suitability-content>
        <app-suitability-additional-notes [suitabilityAdditionalNotesViewModel]="suitabilityAdditionalNotesViewModel"></app-suitability-additional-notes>
      </app-suitability-content>
    </app-suitability-section>
    <!-- end handicap section -->
  </div>
</div>
