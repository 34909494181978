<div  class="modal fade ignore-calendar-unselect" bsModal #staticModal2="bs-modal" [config]="{backdrop: 'static', keyboard: false}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" style="width: 75%" *ngIf="canOpen">
    <div class="modal-content" appAutoWindowHeight [marginTop]="marginTop">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><b>{{'Appointment' | translate}} <span *ngIf="appointment && appointment.student">{{'With' | translate}} {{appointment?.student}} {{'Of' | translate}} {{appointment?.start | datex:  'DD.MM.YYYY HH:mm' : 'UTC'}} - {{appointment?.end | datex: 'HH:mm'}}</span></b></h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="destroy()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--<div class="modal-body" appAutoWindowHeight [marginTop]="220">-->
      <div style="padding: 1rem; font-size:16px;">
        <div *ngIf="hasPrevious" style="margin-bottom: 5px;">
          <div class="panel panel-warning" style="width:100%; font-size: 1.5rem; margin-bottom: 0px;">
              <div class="panel-heading">
                {{appointment?.student}} hat bereits in der Vergangenheit ein Gespräch für diesen Studiengang geführt!
                <button type="button" class="btn btn-xs btn-info pull-right" (click)="fetch()">
                  <i class="fa fa-file-pdf-o"></i>
                </button>
            </div>
          </div>
        </div>
        <tabset>
          <tab [heading]="'Termindetails'">
            <app-appointment-details *ngIf="appointment" [modal]="staticModal2" [studyBranchObject]="studyBranchObject" [confirmAppointmentModal]="confirmAppointmentModal" [studyBranch]="studyBranch" [appointment]="appointment"></app-appointment-details>
          </tab>
          <tab [heading]="'Dossier'" *ngIf="hasStudent">
            <app-documents *ngIf="appointment" [modal]="staticModal2" [confirmAppointmentModal]="confirmAppointmentModal" [appointment]="appointment"></app-documents>
          </tab>
          <tab [heading]="'Eignungsabklärung'" *ngIf="gradable">
            <app-rating #appRating *ngIf="appointment" [modal]="staticModal2" [appointment]="appointment" [appointmentObject]="appointmentObject" [confirmAppointmentModal]="confirmAppointmentModal"></app-rating>
          </tab>
        </tabset>
      </div>
      <!--</div>-->
      <!--<div class="modal-footer">-->
        <!--<button class="btn btn-default" (click)="staticModal2.hide()">-->
          <!--{{'Close' | translate}}-->
        <!--</button>-->
      <!--</div>-->
    </div>
  </div>
</div>
