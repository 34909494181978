<div class="text-center">
  <button class="btn btn-danger btn-xs" (click)="confirmReOpen()" *ngIf="canReOpen">
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<app-confirmation-modal #confirmationModal (onEventConfirmed)="reOpen()"
  [withoutHeader]="true"
  [body]="getConfirmMsg()"
  >
</app-confirmation-modal>