<a  (click)="openModal(infoRating)" style="cursor: pointer;">
  <i class="fa fa-info-circle" aria-hidden="true" style="padding-top:0.6rem; font-size: 2.3rem;"></i>
</a>
<template #infoRating>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'InfoRating' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      Die Eignungsabklärung wird nach jedem "Speichern" auf ihre Vollständigkeit validiert.<br/>
      Abhängig davon wird das "Abschicken" ermöglicht oder nicht!<br/>
      Vollständig ist die Eignungsabklärung, wenn zumindest folgende Auswahlen getroffen wurden:<br/>
      <br/>
      <ul>
        <li><label>Zulassungskriterien:</label><br/>
            Für jedes Kriterium ist eine Auswahl getroffen worden<br/><br/>
        </li>
        <li><label>Nachteilausgleich:</label><br/>
            Eine Auswahl ist getroffen worden<br/><br/>
        </li>
        <li><label>Studienform:</label><br/>
            Eine Auswahl ist getroffen worden<br/><br/>
        </li>
        <li><label>Falls Gesamtbewertung vorhanden:</label><br/>
            Für jedes Kriterium ist eine Auswahl getroffen worden<br/><br/>
        </li>
        <li><label>Mitteilung an Student Services:</label><br/>
            Eine Auswahl ist getroffen worden (inkl. Zusatzangaben, falls nötig)<br/><br/>
        </li>
      </ul>
      Die restlichen Angaben der Eignungsabklärung werden nicht weiter validiert!
  </div>
</template>
