
<div *ngIf="canChange && canEdit">
  <button [disabled]="defined" class="btn btn-default btn-xs" [ngClass]="{'btn-success': isAdded}" (click)="addRole()">
    <i class="fa fa-plus-square"></i>
  </button>


  <button [disabled]="!defined" class="btn btn-default btn-xs" [ngClass]="{'btn-danger': isRemoved}" (click)="removeRole()">
    <i class="fa fa-minus-square"></i>
  </button>
</div>

<div *ngIf="!canChange && canEdit">
  <button class="btn btn-default btn-xs" (click)="canChange = true">
    <i class="fa fa-pencil"></i>
  </button>
</div>