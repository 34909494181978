<!-- greeting and info -->
<div class="row" *ngIf="!noAppointmentsToBook && bookables.hasAppointmentsToBook">
  <div class="col-md-8 col-md-offset-2" style="background-color: white;padding: 36px 36px 10px;">
    <p>
      <b>{{'HelloStudent' | translate}} {{('StudentAddress.' + studentAppointmentsData.student.geschlecht) | translate }}{{studentAppointmentsData.student.firstName}} {{studentAppointmentsData.student.lastName}}</b><br /><br />
      <span [innerHTML]="'PleaseChooseAppointmentFromListText' | translate"></span>
    </p>
    <br />
    <br />
    <table class="table table-responsive table-bordered only-top-bottom" style="border-left:0;border-right:0;">
      <tr>
        <td><label>{{'StudyBranch' | translate}}</label></td>
        <td>{{studentAppointmentsData.subject.studyBranchName}}</td>
      </tr>
    </table>
  </div>
</div>
<!-- table of selectable bookings -->
<div class="row" style="margin-top:2rem;" >
  <div class="col-md-8 col-md-offset-2" style="background-color: white;padding: 36px 36px 10px;">
    <table *ngIf="!selectedAppointment && bookables.hasAppointmentsToBook"
           class="table table-bordered table-striped text-left" style="margin-bottom: 0px;">
      <thead>
      <tr>
        <th style="width:196px;">
          {{'Date' | translate}}
        </th>
        <th>
          {{'MeetingOptions' | translate}}
        </th>
      </tr>
      <tr>
        <th>
          <app-datepicker [prefix]="'Off' | translate " [placeholder]="'SelectDate' | translate" [ngModel]="selectedDate" (ngModelChange)="jumpToDate($event)" [hideClear]="true"></app-datepicker>
        </th>
        <th>
          <ss-multiselect-dropdown
            name="meetingOptions"
            [settings]="selectionSettings"
            [options]="selectableMeetingOptions"
            [(ngModel)]="meetingFilter.value">
          </ss-multiselect-dropdown>
        </th>
      </tr>
      <tr>
        <td colspan="3" *ngIf="hasTimeDiff"><b>{{'LocaleInfoText' | translate }}</b></td>
      </tr>
      </thead>
    </table>
    <div *ngIf="!selectedAppointment && bookables.hasAppointmentsToBook"  style="min-height:300px;max-height: 400px; overflow:auto;border-top:4px solid #dddddd;" class="dayList">
      <!--<div>-->
      <table class="table table-bordered table-striped text-left">
        <tbody *ngFor="let daily of bookables.bookableDaysFiltered" style="overflow: auto;">
        <tr>
          <td colspan="3" [attr.data-date]="daily.day | date: 'dd.MM.yyyy'" class="fc-list-heading"><b>{{daily.dayString(studentAppointmentsData.subject.languageCode)}}</b></td>
        </tr>

        <tr *ngFor="let studyAppointment of daily.appointmentsFiltered">
          <td style="width:196px">
            <!--{{studyAppointment.start| date: 'dd.MM.yyyy' }} {{studyAppointment.start | date: 'HH:mm'}} - {{studyAppointment.end | date: 'HH:mm'}}-->
            {{studyAppointment.start | datex: 'HH:mm'}} - {{studyAppointment.end | datex: 'HH:mm'}}
          </td>
          <td>
            {{meetingOptionService.getAllMeetingOptionsLabeled(studyAppointment.meetingTypeOptions)}}
            <span *ngIf="isDifferent(studyAppointment.start)">
              <br/>{{'YourTime' | translate }}: {{studyAppointment.start | datex: 'DD.MM.YYYY HH:mm': true}} - {{studyAppointment.end | datex: 'HH:mm': true}}
            </span>
          </td>
          <td style="width:160px;">
            <div>
              <button class="btn btn-primary btn-block"
                      (click)="selectStudyAppointment(studyAppointment)"
                      [disabled]="isBooked(studyAppointment)">
                {{(isBooked(studyAppointment) ? 'Booked' : 'Book') | translate}}

              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>


<div>
  <!-- If there aren't any appointments to book -->
  <app-no-appointment-to-book *ngIf="loaded && (noAppointmentsToBook || !bookables.hasAppointmentsToBook)"
                              [studentAppointmentsData]="studentAppointmentsData"></app-no-appointment-to-book>

  <!-- if a StudyAppointment is selected -->
  <app-appointment-book *ngIf="selectedAppointment" [(selectedStudyAppointment)]="selectedAppointment" [studentAppointmentsData]="studentAppointmentsData"></app-appointment-book>
</div>

<app-confirmation-modal #confirmationModal (onEventConfirmed)="resetDateToPrevious()"
                        [withoutHeader]="true"
                        [body]="'NoAppointmentAfterGivenDate' | translate"
                        [onlyConfirm]="true"></app-confirmation-modal>
