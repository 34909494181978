<div>

  <input type="radio" name="decision" [(ngModel)]="suitabilityStudentAcceptanceViewModel.decision" [value]="acceptedWithoutReservation" (change)="valuesChanged()"  /> {{'AcceptedWithoutReservation' | translate}} <br />
  <input type="radio" name="decision" [(ngModel)]="suitabilityStudentAcceptanceViewModel.decision" [value]="acceptedWithReservations" (change)="valuesChanged()" /> {{'AcceptedWithReservation' | translate}} <br />

  <!-- view only when accepted with reservation-->
  <div style="padding-left: 3rem">
    <input type="checkbox" name="decision" [(ngModel)]="suitabilityStudentAcceptanceViewModel.bachelor" (change)="valuesChanged(this.acceptedWithReservations)" /> {{'ReservationBachelor' | translate}}<br />
    <input type="checkbox" name="decision" [(ngModel)]="suitabilityStudentAcceptanceViewModel.qualification" (change)="valuesChanged(this.acceptedWithReservations)" /> {{'ReservationPostQualifikation' | translate}}<br />
    <input type="checkbox" name="decision" [(ngModel)]="suitabilityStudentAcceptanceViewModel.convergence" (change)="valuesChanged(this.acceptedWithReservations)" /> {{'ReservationConvergence' | translate}}<br />
    <input type="checkbox" name="decision" [(ngModel)]="suitabilityStudentAcceptanceViewModel.englishC1" (change)="valuesChanged(this.acceptedWithReservations)" /> {{'ReservationEnglish' | translate}}<br />
    <input type="checkbox" name="decision" [(ngModel)]="suitabilityStudentAcceptanceViewModel.germanC1" (change)="valuesChanged(this.acceptedWithReservations)" /> {{'ReservationGerman' | translate}}<br />
    <input type="checkbox" name="decision" [(ngModel)]="suitabilityStudentAcceptanceViewModel.englishB2" (change)="valuesChanged(this.acceptedWithReservations)" /> {{'ReservationEnglishB2' | translate}}<br />
  </div>
  <!-- -->
  <input type="radio" name="decision" [(ngModel)]="suitabilityStudentAcceptanceViewModel.decision" [value]="rejected" (change)="valuesChanged()" /> {{'DecisionRejected' | translate}}<br /><br />
    <textarea name="rejectionReason" [(ngModel)]="suitabilityStudentAcceptanceViewModel.rejectionReason" [readonly] = "!isRejected" [placeholder]="(isRejected ? 'RejectReason' : '')  | translate" class="form-control" style="width: 100%;min-height:78px;" (change)="valuesChanged()">
  </textarea>
</div>
