<table class="table table-responsive table-striped table-bordered">
  <thead>
  <tr>
    <td>{{'Module' | translate}}</td>
    <td>{{'ModuleAbbreviation' | translate}}</td>
    <td>{{'PossibleImplementation' | translate}}</td>
    <td>{{'SelfStudy' | translate}}</td>
    <td>{{'ECTS' | translate}}</td>
    <td>{{'Semester' | translate}}</td>
  </tr>
  </thead>
  <tbody>

  <tr *ngFor="let qualification of getQualifications()">
    <td>{{qualification.moduleName}} {{qualification.sort}}</td>
    <td>{{qualification.moduleNameShort}}</td>
    <td>
      <span>{{getPossibleImplementationText(qualification.possibleImplementation)}}</span>
    </td>
    <td><input type="checkbox" [(ngModel)]="qualification.selfStudy" (change)="valueChanges()" [disabled]="qualification.semesterProvided"/></td>
    <td>{{qualification.ects}}</td>
    <td>
      <ng-container *ngIf="qualification.possibleImplementation === hs.toString()">
        <ss-multiselect-dropdown extended [autoCloseAfterMilliseconds]=1500 [disabled]="qualification.selfStudy"
                                 [options]="this.listForHs"
                                 [settings]="selectionSettings"
                                 [(ngModel)]="qualification.semester" (dropdownClosed)="valueChanges()">
        </ss-multiselect-dropdown>
      </ng-container>
      <ng-container *ngIf="qualification.possibleImplementation === fs.toString()">
        <ss-multiselect-dropdown extended [autoCloseAfterMilliseconds]=1500 [disabled]="qualification.selfStudy"
                                 [options]="this.listForFs"
                                 [settings]="selectionSettings"
                                 [(ngModel)]="qualification.semester" (dropdownClosed)="valueChanges()">
        </ss-multiselect-dropdown>
      </ng-container>
      <ng-container *ngIf="qualification.possibleImplementation === both.toString()">
        <ss-multiselect-dropdown extended [autoCloseAfterMilliseconds]=1500 [disabled]="qualification.selfStudy"
                                 [options]="this.listForHsFs"
                                 [settings]="selectionSettings"
                                 [(ngModel)]="qualification.semester" (dropdownClosed)="valueChanges()">
        </ss-multiselect-dropdown>
      </ng-container>

      <!--      <select [(ngModel)]="qualification.semester" class="form-control" (change)="valueChanges()">
              <option value=""></option>
              <ng-container *ngIf="qualification.possibleImplementation === hs.toString()">
                <option *ngFor="let possibleImpl of this.listForHs" [value]="possibleImpl.value">
                  {{possibleImpl.label}}
                </option>
              </ng-container>
              <ng-container *ngIf="qualification.possibleImplementation === fs.toString()">
                <option *ngFor="let possibleImpl of this.listForFs" [value]="possibleImpl.value">
                  {{possibleImpl.label}}
                </option>
              </ng-container>
              <ng-container *ngIf="qualification.possibleImplementation === both.toString()">
                <option *ngFor="let possibleImpl of this.listForHsFs" [value]="possibleImpl.value">
                  {{possibleImpl.label}}
                </option>
              </ng-container>-->
      <!--<option *ngFor="let possibleImpl of getListOfPossibleImplementations(qualification.possibleImplementation)" [value]="possibleImpl.value" >
        {{possibleImpl.label}}
      </option>
    </select>-->


    </td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="5">
      {{'TotalEcts' | translate}}
    </td>
    <td>{{getTotalEcts()}}</td>
  </tr>
  </tfoot>
</table>


