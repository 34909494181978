<div id="sidebar-wrapper" style="padding: .55rem;">
  <div class="panel panel-default">
    <div class="panel-heading">
      <span>Studiengänge</span>
      <span class="pull-right">
        <button type="button" class="btn btn-default btn-xs" style="margin-right:1rem;" (click)="createRequested.emit()"><i class="fa fa-plus"></i></button>
        <span class="badge">{{studyBranchSubjects?.length}}</span>
      </span>
    </div>
    <div class="list-group">
      <a *ngFor="let studyBranchSubject of studyBranchSubjects | orderBy : ['studyBranch.name']"
         [routerLink]="studyBranchSubject.configUrl"
         [routerLinkActive]="'active'"
         class="list-group-item">
        <div class="row">
          <div [ngClass]="{'col-md-9': isUndefined(studyBranchSubject.subject), 'col-md-12': !isUndefined(studyBranchSubject.subject)}">
            <div style="width:10px;"><p class="brand-color-display" [ngStyle]="{'background-color': studyBranchSubject.studyBranch.color}"></p></div>
            <div style="margin-left: 28px;" [ngClass]="{'inactive': !studyBranchSubject.studyBranch.active }">{{studyBranchSubject.studyBranch.name}}</div>
          </div>
          <div *ngIf="!studyBranchSubject.noConfig && isUndefined(studyBranchSubject.subject)" class="col-md-3">
            <!-- indicator -->
            <div class="label label-warning pull-right" style="font-size:11px !important; font-weight: normal;" >{{'Undefined' | translate}}</div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
