<div class="MenuContainer Fixed">
  <!-- Fixed navbar -->
  <div class="smlBlue">
    <div id="navigationMenu" class="navbar navbar-default" [ngClass]="{'gradientEnd' : isProduction, 'gradientEnd-staging' : isStaging, 'gradientEnd-local' : !isProduction && !isStaging}" role="navigation">
      <div class="pull-left" style="margin-right: 20px;">
        <img src="assets/logo1.png" class="pull-left" style="margin-left: 10px;margin-top:4px;max-height: 40px;">
      </div>
      <div class="navbar-header">
        <a class="navbar-brand"><strong><!--Personalantrag--></strong></a>


        <button type="button" class="navbar-toggle" (click)="isCollapsed = !isCollapsed">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>

      <div>
        <ul class="nav navbar-nav navbar-collapse" [class.collapse]="isCollapsed" style="margin-left: 136px"
            style.gt-sm="margin-left: 110px;">
          <li>
            <a routerLinkActive="active" (click)="isCollapsed = true" [routerLink]="getCurrentHome()">
              <i class="fa fa-home" style="font-size: 1.5em;"></i>
            </a>
          </li>
          <li style="padding:1rem;" *ngIf="!isStudent()">
            <!--<a routerLinkActive="active" (click)="isCollapsed = true;openHelpModal()">-->
              <!--<i class="fa fa-info-circle" style="font-size: 1.5em;"></i>-->
            <!--</a>-->
            <app-help-modal></app-help-modal>
          </li>
          <li style="padding:1rem;" *ngIf="!isStudent()">
            <i class="fa fa-bar-chart" style="padding-top:0.6rem; font-size: 2.3rem;color: white; cursor: pointer;" (click)="openOverview()"></i>
          </li>
          <li style="padding:1rem;" *ngIf="isSgl()">
            <i class="fa fa-table" style="padding-top:0.6rem; font-size: 2.3rem;color: white; cursor: pointer;" (click)="export()"></i>
          </li>
          <!--<li *ngIf="isAdmin()">-->
          <!--<a routerLinkActive="active" (click)="isCollapsed = true" routerLink="/admin/overview">Admin</a>-->
          <!--</li>-->
          <!--<li *ngIf="isAdmin()">-->
          <!--<a routerLinkActive="active" (click)="isCollapsed = true" routerLink="/student/login">Ansicht Student</a>-->
          <!--</li>-->
          <!--<li *ngIf="isAdmin()">-->
          <!--<a routerLinkActive="active" (click)="isCollapsed = true" routerLink="/sgl/login">Ansicht SGL</a>-->
          <!--</li>-->

        </ul>
      </div>


      <div class="pull-right" style.lt-md="display:none;" style="margin-right: 20px;margin-top:3px;">
        <img src="assets/test1.png" style="max-height: 40px;">
      </div>

      <div class="pull-right navbar-collapse" [class.collapse]="isCollapsed" style="border-top: 0px;margin-top:7px;">

        <!-- user profile navigation -->
        <div style.gt-sm="margin-right: 0px; padding-top:18px;">
          <div class="btn-group" dropdown>
            <a dropdownToggle type="button" class="btn btn-link dropdown-toggle user-profile">
              <i class="glyphicon glyphicon-user"></i><span class="user-shortname">{{getUserDisplayName()}}</span><span
              class="caret"></span>
            </a>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" class="link" (click)="logout()"><i
                class="fa fa-sign-out"></i>
                {{'Logout' | translate}}</a></li>
              <!--<ng-container *ngIf="!isStudent()">-->
                <!--<li class="divider dropdown-divider"></li>-->
                <!--<li role="menuitem">-->
                  <!--<a class="dropdown-item" class="link" (click)="changeLanguage('de')">-->
                    <!--<i class="fa fa-language"></i>-->
                    <!--{{'German' | translate}}-->
                    <!--<i *ngIf="isLanguage('de')" class="fa fa-circle" style="font-size: 12px;float: right;margin-top: 5px;"></i>-->
                  <!--</a>-->
                <!--</li>-->
                <!--<li role="menuitem">-->
                  <!--<a class="dropdown-item" class="link" (click)="changeLanguage('en')">-->
                    <!--<i class="fa fa-language"></i>-->
                    <!--{{'English' | translate}}-->
                    <!--<i *ngIf="isLanguage('en')" class="fa fa-circle" style="font-size: 12px;float: right;margin-top: 5px;"></i>-->
                  <!--</a>-->
                <!--</li>-->
              <!--</ng-container>-->
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item">{{version}}</a></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- navigation pulled to the right -->
      <div class="pull-right">
        <ul class="nav navbar-nav navbar-collapse" [class.collapse]="isCollapsed"
            style="margin-left: 136px; border-top: 0px;margin-top:7px;"
            style.gt-sm="margin-left: 110px;" *ngIf="isAdmin()">
          <div class="btn-group" dropdown>
            <a dropdownToggle type="button" class="btn btn-link dropdown-toggle user-profile" routerLinkActive="active">
              <span class="user-shortname"><i class="fa fa-cog"></i>
                Admin
              </span>
              <span class="caret"></span>
            </a>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li role="menuitem">
                <a class="dropdown-item" class="link" (click)="isCollapsed = true" routerLink="/config/study-branch">
                  <i class="fa fa-list" aria-hidden="true"></i>
                  {{'NavStudyBranch' | translate}}
                </a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" class="link" (click)="isCollapsed = true"
                   routerLink="/config/students-overview">
                  <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                  {{'NavInterestedStudents' | translate}}
                </a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" class="link" (click)="isCollapsed = true"
                   routerLink="/configs">
                  <i class="fa fa-wrench" aria-hidden="true"></i>
                  {{'NavConfiguration' | translate}}
                </a>
              </li>

              <li class="divider dropdown-divider" *ngIf="isSuperAdmin()"></li>
              <li role="menuitem" *ngIf="isSuperAdmin()">
                  <a class="dropdown-item" class="link" (click)="isCollapsed = true"
                    routerLink="/super-admin">
                  <i class="fa fa-server" aria-hidden="true"></i>
                  {{'SuperAdmin' | translate}}
                </a>
              </li>

            </ul>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-overview-modal></app-overview-modal>