<table class="table table-responsive table-striped table-bordered">
  <thead>
  <tr>
    <td></td>
    <td>{{'VeryGood' | translate}}</td>
    <td>{{'GoodEnough' | translate}}</td>
    <td>{{'NotGood' | translate}}</td>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>{{'GeneralFunctionalResponsibility' | translate}} </td>
    <td><input type="radio" name="generalExpertise" [(ngModel)]="suitabilityOverallRatingViewModel.generalExpertise" [value]="great" (change)="valuesChanged()" /></td>
    <td><input type="radio" name="generalExpertise" [(ngModel)]="suitabilityOverallRatingViewModel.generalExpertise" [value]="good" (change)="valuesChanged()" /></td>
    <td><input type="radio" name="generalExpertise" [(ngModel)]="suitabilityOverallRatingViewModel.generalExpertise" [value]="insufficient" (change)="valuesChanged()" /></td>
  </tr>
  <tr>
    <td>{{'ImmersionFunctionalResponsibility' | translate}}</td>
    <td><input type="radio" name="inDepthExpertise" [(ngModel)]="suitabilityOverallRatingViewModel.inDepthExpertise" [value]="great" (change)="valuesChanged()" /></td>
    <td><input type="radio" name="inDepthExpertise" [(ngModel)]="suitabilityOverallRatingViewModel.inDepthExpertise" [value]="good" (change)="valuesChanged()" /></td>
    <td><input type="radio" name="inDepthExpertise" [(ngModel)]="suitabilityOverallRatingViewModel.inDepthExpertise" [value]="insufficient" (change)="valuesChanged()" /></td>
  </tr>
  <tr>
    <td>{{'MotivationFunctionalResponsibility' | translate}}</td>
    <td><input type="radio" name="motivationAndCompliance" [(ngModel)]="suitabilityOverallRatingViewModel.motivationAndCompliance" [value]="great" (change)="valuesChanged()" /></td>
    <td><input type="radio" name="motivationAndCompliance" [(ngModel)]="suitabilityOverallRatingViewModel.motivationAndCompliance" [value]="good" (change)="valuesChanged()" /></td>
    <td><input type="radio" name="motivationAndCompliance" [(ngModel)]="suitabilityOverallRatingViewModel.motivationAndCompliance" [value]="insufficient" (change)="valuesChanged()" /></td>
  </tr>
  <tr>
    <td>{{'CommunicationFunctionalResponsibility' | translate}}</td>
    <td><input type="radio" name="communicationCompetence" [(ngModel)]="suitabilityOverallRatingViewModel.communicationCompetence" [value]="great" (change)="valuesChanged()" /></td>
    <td><input type="radio" name="communicationCompetence" [(ngModel)]="suitabilityOverallRatingViewModel.communicationCompetence" [value]="good" (change)="valuesChanged()" /></td>
    <td><input type="radio" name="communicationCompetence" [(ngModel)]="suitabilityOverallRatingViewModel.communicationCompetence" [value]="insufficient" (change)="valuesChanged()" /></td>
  </tr>
  </tbody>
</table>
<br />
<table class="table table-bordered table-striped table-responsive">
  <thead>
  <tr>
    <td></td>
    <td>{{'Passed' | translate}}</td>
    <td>{{'Failed' | translate}}</td>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>{{'SuitabilityDeclaration' | translate}}</td>
    <td><input type="radio" name="passed" [(ngModel)]="suitabilityOverallRatingViewModel.passed" [value]="true" (change)="valuesChanged()" /></td>
    <td><input type="radio" name="passed" [(ngModel)]="suitabilityOverallRatingViewModel.passed" [value]="false" (change)="valuesChanged()" /></td>
  </tr>
  </tbody>
</table>
