<div *ngIf="studyBranch">
  <!-- header -->
  <div class="page-header">
    <h2>
      <p class="brand-color-display" [ngStyle]="{'background-color': studyBranch.color}"></p> {{studyBranch.name}}
    </h2>
  </div>

  <!-- action bar -->
  <div class="well well-sm" [ngStyle]="!isPristine && {'background-color': '#fcf8e3'}">
    <button type="button" [disabled]="isPristine || saving" class="btn btn-success" (click)="saveStudyBranch()"><i class="fa fa-save"></i> {{'Save' | translate}}</button>
    <button type="button" [disabled]="isPristine" class="btn btn-danger" style="margin-left: 20px;" (click)="removeChanges()"><i class="fa fa-trash" aria-hidden="true"></i> {{'DiscardChanges' | translate}}</button>
    <button *ngIf="deletable" type="button" class="btn btn-danger" style="margin-left: 20px;" (click)="confirmDelete.showModal()"><i class="fa fa-trash" aria-hidden="true"></i></button>
    <!-- <button type="button" class="btn btn-default" (click)="check()">CheckMe</button> -->
  </div>
  <tabset>
    <tab heading="Stammdaten">
      <div class="row">
        <div class="col-md-12">
          <app-core-data-edit [studyBranch]="studyBranch"></app-core-data-edit>
        </div>
      </div>
    </tab>
    <tab heading="Gesprächspartner" *ngIf="!noConfig">
      <div class="row">
        <div class="col-md-12">
          <app-host-data-edit *ngIf="studyBranch" [studyBranch]="studyBranch" title="Gesprächspartner" (hostsChanged)="setHosts($event)"></app-host-data-edit>
        </div>
      </div>
    </tab>
    <tab heading="Bewertungsraster" *ngIf="!noConfig">
      <div class="row">
        <div class="col-md-12">
          <app-admin-suitability-main [studyBranchId]="studyBranch.id"></app-admin-suitability-main>
          <!--<app-host-data-edit *ngIf="studyBranch" [studyBranch]="studyBranch" title="Gesprächspartner" (hostsChanged)="setHosts($event)"></app-host-data-edit>-->
        </div>
      </div>
    </tab>
  </tabset>
</div>

<div *ngIf="!studyBranch">
  <app-loading-indicator></app-loading-indicator>
</div>


<app-ngx-confirmation-modal #confirmDelete
  [title]="'Studiengang löschen'"
  (onEventConfirmed)="delete()"
  [confirmDisabled]="!deletable">
  <app-ngx-confirmation-modal-content>
    Wollen Sie den Studiengang '{{ studyBranch?.name }}' wirklich löschen ?
  </app-ngx-confirmation-modal-content>

</app-ngx-confirmation-modal>