<div fxLayout="row" fxFill>
    <div fxFill class="row">
        <div class="col-md-offset-1 col-md-10" style="margin-top:2rem;">
        <div class="panel panel-primary">
            <div class="panel-heading">{{'Mitarbeiter' | translate}}</div>
            <div class="panel-body">
            <div style="overflow:auto;">
                <div style="padding:1rem;">
                <div *ngIf="loading">
                  <app-loading-indicator [size]="'35px'" [margin]="'0.5em auto'"></app-loading-indicator>
                </div>
                <app-ngx-filter-table
                    *ngIf="employees"
                    [data]="employees"
                    [options]="options"
                    [maxRowsPerPage]="15"
                    [styleClass]="'table table-bordered table-condensed table-responsive table-striped table-hover filtertable-table'">
                </app-ngx-filter-table>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</div>
      