<div class="modal-body" appAutoWindowHeight [marginTop]="267">
  <div class="tabDiv" style="margin-top: 1rem;">
    <table class="table table-responsive ">
      <tbody>
      <tr>
        <td><label>{{'InterestedPerson' | translate}}</label></td>
        <td>
          <div class="data-text">{{appointment?.student}}</div>
        </td>
      </tr>
      <tr>
        <td><label>{{'StudyBranch' | translate}}</label></td>
        <td>
          <div class="data-text">{{studyBranch}}</div>
        </td>
      </tr>
      <tr>
        <td><label>{{'Date' | translate}}</label></td>
        <td>
          <div class="data-text">{{appointment?.start | datex: 'DD.MM.YYYY HH:mm' : 'UTC'}} - {{appointment?.end |
            datex: 'HH:mm'}}
          </div>
        </td>
      </tr>
      <tr>
        <td><label>{{'Meeting' | translate}}</label></td>
        <td>
          <div class="data-text">{{appointment?.meetingType | translate }}</div>
        </td>
      </tr>
      <tr>
        <td><label>{{'PhoneNr' | translate}}</label></td>
        <td>
          <div class="data-text">{{appointment?.studentTelNr}}</div>
        </td>
      </tr>
      <tr *ngIf="appointment?.meetingInfo">
        <td><label>{{'SkypeName' | translate}}</label></td>
        <td>
          <div class="data-text">{{appointment?.meetingInfo}}</div>
        </td>
      </tr>
      <tr *ngIf="isConfirmed && (appointment.place || appointment.placeAdditional)">
        <td><label>{{'Place' | translate}}</label></td>
        <td>
          <div class="data-text" *ngIf="appointment.place">{{appointment.place}}</div>
          <div class="data-text" *ngIf="appointment.placeAdditional">{{appointment.placeAdditional}}</div>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="row" *ngIf="canConfirm()">
      <div class="col-md-12">
        <div class="form-group">
          <label>{{'PlaceInput' | translate}}</label>
          <select class="form-control" [(ngModel)]="confirmAppointmentModel.placeSelect" name="place">
            <option value="null"></option>
            <option *ngFor="let place of places" [value]="place">{{place}}</option>
          </select>
        </div>
        <div class="form-group">
          <label>{{'PlaceAdditional' | translate}}</label>
          <input type="text" class="form-control" [(ngModel)]="confirmAppointmentModel.placeAdditional"
                 name="placeAdditional"/>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <span *ngIf="canConfirm()">
    <button class="btn btn-primary pull-left" (click)="confirmAppointmentConfirm()" style="margin-right: 8rem;" [disabled]="!placeIsValid()">
      {{'ConfirmAppointment' | translate}}
    </button>
  </span>
  <span>
    <button class="btn btn-danger pull-left" (click)="deleteAppointmentConfirm()" [disabled]="!canDelete()">
      {{'DeleteAppointment' | translate}}
    </button>
  </span>
  <span>
    <button class="btn btn-default" (click)="this.appointmentManageService.modal.emit(null)">
      {{'Close' | translate}}
    </button>
  </span>
</div>

<app-confirmation-modal #confirmationModal (onEventConfirmed)="confirmAppointment()" class="modal-center"
  [withoutHeader]="true"
  [body]="'ConfirmAppointmentConfirm' | translate"
  >
</app-confirmation-modal>

<app-confirmation-modal #deleteModal (onEventConfirmed)="deleteAppointment()" class="modal-center"
  [withoutHeader]="true"
  [body]="'DeleteAppointmentConfirm' | translate"
  >
</app-confirmation-modal>