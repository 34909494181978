<ng-container *ngIf="employeeSignature ">


  <div class="panel panel-default">
    <div class="panel-heading">
       {{'EmailSignatureOf' | translate}} {{employee.lastName}} {{employee.firstName}} ({{employee.pid}})
    </div>
    <table class="table table-bordered table-condensed table-responsive">
      <thead>
      <tr>
      </tr>
      </thead>
      <tbody *ngIf="employeeSignature">
      <tr>
        <td>{{'EmployeeFunction' | translate}}</td>
        <td><input type="text" class="form-control" [(ngModel)]="employeeSignature.function" (change)="valueChanged()"/></td>
      </tr>
      <tr>
        <td>{{'EmployeeDivision' | translate}}</td>
        <td><input type="text" class="form-control" [(ngModel)]="employeeSignature.division" (change)="valueChanged()"/></td>
      </tr>
      <tr>
        <td>{{'EmployeeAddress' | translate}}</td>
        <td><textarea type="text" style="min-height:100px;" class="form-control"
                      [(ngModel)]="employeeSignature.address" (change)="valueChanged()"></textarea></td>
      </tr>
      <tr>
        <td>{{'SkypeName' | translate}}</td>
        <td><input type="text" class="form-control" [(ngModel)]="employeeSignature.skypeName" (change)="valueChanged()"/></td>
      </tr>
      </tbody>
    </table>


  </div>
  <!-- signatur preview -->
  <p>
    <span>{{'KindRegards' | translate}}</span><br/><br/>
    <span *ngIf="employeeSignature" style="font-size: 16px;">
        <span>{{TitleAndName}}</span><br/>
        <span *ngIf="employeeSignature.function">{{employeeSignature.function}}<br/></span><br/>
        <span><b>{{'SchoolSignature' | translate}}</b><br/></span>
        <span *ngIf="employeeSignature.division">{{employeeSignature.division}}<br/></span>
      <span>Zürcher Hochschule für Angewandte Wissenschaften</span>
        <span *ngIf="employeeSignature.address">
          <br/>
          <span [innerHTML]="employeeSignature.address | includeLineBreaks"></span>
        </span>
  <span><br />Postfach</span><br/>
  <span>8401 Winterthur</span><br/><br/>
  </span>
  <span *ngIf="employeeSignatureView && employeeSignatureView.telNrShort">Tel. +41 58 934 {{employeeSignatureView.telNrShort}}<br/></span>
  <span *ngIf="employee && employee.pid">{{employee.pid}}@zhaw.ch<br/></span>
  <span *ngIf="hasSkypeName">{{'SkypeName' | translate }}: {{employeeSignature.skypeName}}<br/></span>
  <!--{{employee.pid}}@zhaw.ch<br />-->
  <a href="http://www.zhaw.ch/sml">www.zhaw.ch/sml</a><br/>
  <a href="http://www.zhaw.ch/de/sml/studium/master">www.zhaw.ch/de/sml/studium/master</a>
  </p>


</ng-container>
