<div class="row">
  <div class="col-md-8 col-md-offset-2" style="background-color: white;padding: 36px 36px 0px;">
    <p>
      <b>{{'HelloStudent' | translate}} {{('StudentAddress.' + studentAppointmentsData.student.geschlecht) | translate }}{{studentAppointmentsData.student.firstName}} {{studentAppointmentsData.student.lastName}}</b><br/><br/>
      <span [innerHTML]="'NoOpenTasks' | translate"></span>
      <br />
      <br />
      <br />
    </p>

    <table class="table table-responsive table-bordered" style="border-left:0;border-right:0;">
      <tr>
        <td><label>{{'StudyBranch' | translate}}</label></td>
        <td>{{studentAppointmentsData.subject.studyBranchName}}</td>
      </tr>
      <tr>
        <td><label>{{'TimePeriod' | translate}}</label></td>
        <td>{{getPeriodRange()}}</td>
      </tr>
    </table>
  </div>
</div>
